import {LinearGradient} from 'expo-linear-gradient';
import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as DocumentPicker from 'expo-document-picker';
import i18n from 'i18n-js';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  FlatList
} from 'react-native';
import { addPdf, getPdfData, resetAddPdf, updateIsPrinted } from '../actions/productAction';
import {GetBothSettings, GetGeneralSetting} from '../actions/userAction';
import { fetchData, handlePrinterSelection } from '../../qzservice';
import PrinterShow from './directPrinting/printerShow';
import Prompt from '../commons/prompt';
import AsyncStorage from '@react-native-async-storage/async-storage';
import PopUpModel from './scanpack/pop_up_model';
import ListAlert from './scanpack/listAlert';

function printJob() {
    const pickerSelectedOptionsPdfSize= [
        {
          label: '4” x 8”',
           value: '4x8'
        },
        {
          label: '4” x 6”',
          value: '4x6',
        },
        {
          label: '3” x 1”',
          value: '3x1',
        },
        {
          label: '2” x 1”',
          value: '2x1'
        },
        {
          label: '1.5” x 1”',
          value: '1.5x1'
        }
      ]
    const MAX_FILES_PER_DAY = 50; // Maximum files allowed per day
    let filesPickedCount = 0;
    const [selectedTab, setSelectedTab] = useState('unprinted');
    const [pickerSizeSelectedOption, SetPickerSizeSelectedOption] = useState('4x6');
    const dispatch = useDispatch();
    const {isPdfAdded} =  useSelector(state => state.product);
    const {pdfsList} =  useSelector(state => state.product);
    const {bothSettings} = useSelector(state => state.user);
    const general_settings = bothSettings?.data?.data?.general_setting;
    let pdfs = pdfsList?.data?.pdfs

    const [selectedPDFs, setSelectedPDFs] = useState([]);
    const [printers, setPrinters] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [prompt, setPrompt] = useState(false);
    const [urlSet, setUrlSet] = useState('');
    const [pdfHeight, setPdfHeight] = useState(0);
    const [pdfWidth, setPdfWidth] = useState(0);
    const [currentItem, setCurrentItem] = useState();
    const [popUpModelShow, setPopUpModelShow] = useState(false);
    const [popUpSizeModel, setPopUpSizeModel] = useState(false);
    const [msg, setMsg] = useState('');


    const handleTabPress = (tab) => {
        setSelectedTab(tab);
    };

    const handlePrinted = (item) => {
        setPrompt(true);
        setCurrentItem(item)
    };

    const alertBox = (msg) => {
        setPopUpModelShow(true)
        setMsg(msg)
        setTimeout(
          function () {
            setPopUpModelShow(false)
          }.bind(this),
          4000
        );
      };

    useEffect(() => {
        const today = new Date().toLocaleDateString();
        const storedDate = localStorage.getItem('pickedDate');
    
        if (storedDate !== today) {
         AsyncStorage.setItem('filesPickedCount', '0'); 
         localStorage.setItem('pickedDate', today);
        }
        dispatch(GetBothSettings());
        dispatch(GetGeneralSetting());
      }, []);

    useEffect(() => {
        dispatch(getPdfData());
        dispatch(resetAddPdf());
      }, [isPdfAdded]);

    useEffect(() => {
        selectedPDFs.length > 0 && dispatch(addPdf(selectedPDFs));
      }, [selectedPDFs]);

    const pickDocument = async () => {
        try {
        const result = await DocumentPicker.getDocumentAsync({
            type: 'application/pdf',
            copyToCacheDirectory: true,
            multiple: true
        });

        if (result.type === 'success') {
            const { name, uri, size } = result;
            AsyncStorage.getItem('filesPickedCount').then((storedCount) => {
                if (storedCount >= MAX_FILES_PER_DAY || size > 41943040) {
                    alertBox(i18n.t('directprinting.alertForPdfLimit'));
                    return;
                }
                let printSize;
                AsyncStorage.getItem("@MyFlatList:selectedItem").then((value) => {
                    if (value) {
                        printSize = value.replace(/"/g, '').split('x');
                        setSelectedPDFs([...selectedPDFs, { name, uri, width: printSize[0], height: printSize[1] }]);
                    }
                });
                filesPickedCount = parseInt(storedCount, 10) || 0;
                AsyncStorage.setItem('filesPickedCount', (filesPickedCount + 1).toString());
            })
            setPopUpSizeModel(false);
        } else {
            console.log('Document picker cancelled or failed');
        }
        } catch (error) {
            console.error('Error picking document:', error);
        }
    };

    const handlePrintedPdf = (item) => {
        item.is_pdf_printed = true;
        setSelectedPDFs(selectedPDFs.filter((items) => items.url !== items.url));
        updateIsPrinted(item);
    }

    const selectPrinter = async(item) =>{
        setUrlSet(item.url)
        setPdfWidth(item.pdf_width);
        setPdfHeight(item.pdf_height);
        if(general_settings?.print_packing_slips === true){
            let data = await fetchData();
            setPrinters(JSON.parse(JSON.stringify(data.printersList)))
            let slipPrinter = await AsyncStorage.getItem('user_selected_printer_packing_slip') || '';
            if(slipPrinter.length !== 0)
            {
                setTimeout(() => {
                    handlePrinterSelection(
                    JSON.parse(slipPrinter),
                    item?.url,
                    '',
                    '',
                    {width: item.pdf_width, height: item.pdf_height}
                    );
                }, [3000]);
                handlePrintedPdf(item);
                return
            }
            data.printersList.length > 0 ? setModalVisible(true) : alertBox(i18n.t('directprinting.alertForNoPrinter'))
            return
        }
        handlePrintedPdf(item);
        window.open(item?.url)
    }

    const handlePrinter = (item) =>{
        selectPrinter(item);
        setCurrentItem(item)
    }
  return (
    <LinearGradient
        colors={[
          '#000',
          '#000',
          '#5c778f',
          '#5c778f',
          '#253c57',
          '#253c57',
          '#000'
        ]}
        start={[-0.5, -1]}
        style={{
          flex: 1,
          paddingBottom: 15
    }}>
        {popUpModelShow && (
            <PopUpModel
                closeAlert={()=>{setPopUpModelShow(false)}}
                message={msg}
                messageTypeSuccess= {false}
            />
        )}
        {popUpSizeModel && (
        <ListAlert
              msg={i18n.t("directprinting.printerSizeSelectionText")}
              listData={pickerSelectedOptionsPdfSize}
              pickDocument={()=>{
                pickDocument();
              }}
        ></ListAlert>
        )}
        {modalVisible && (
            <PrinterShow
            dataPrinter={printers}
            handlePrinterSelection={async printer => {
                await handlePrinterSelection(
                    printer,
                    urlSet,
                    '',
                    'packing_slip',
                    {width: pdfWidth,height: pdfHeight}
                );
                setModalVisible(false)
                updateIsPrinted(currentItem);
                currentItem.is_pdf_printed = true
                setSelectedPDFs(selectedPDFs.filter((items) => items.url !== items.url));
            }}
            closePrompt={() => {
                setModalVisible(false)
            }}
            />
        )}
        {prompt && (
            <Prompt
            onPressCancel={() => {
                setPrompt(false);
            }}
            onPressOk={() => {
                setPrompt(false);
                selectPrinter(currentItem)
            }}
            msg={i18n.t('directprinting.confirmAgainPrinting')}
            />
        )}
        <View style={styles.mainContainer}>
            <View style={styles.mainContainerSecond}>
                <TouchableOpacity 
                    testID='unprintedBtn'
                    style={[styles.activeBtnContainer,
                        {backgroundColor: selectedTab === 'unprinted' ? 'rgba(157, 176, 193, 0.3)' : ''}]}
                    onPress={() => handleTabPress('unprinted')}>
                    <Text style={[styles.activeBtn, 
                        {color: selectedTab === 'unprinted' ? '#FFFFFF': '#788A9E'}]}>
                        Un-Printed
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                    testID='printedBtn'
                    style={[styles.activeBtnContainer,
                           {backgroundColor: selectedTab === 'printed' ? 'rgba(157, 176, 193, 0.3)' : ''}]}
                    onPress={() => handleTabPress('printed')}>
                    <Text style={[styles.activeBtn,
                        {color: selectedTab === 'printed' ? '#FFFFFF': '#788A9E'}]}>
                        Printed
                    </Text>
                </TouchableOpacity>
            </View>
            <View style={styles.printContainer} />
            {selectedTab === 'unprinted' &&
                <FlatList
                data={pdfs?.filter((pdf) => pdf?.is_pdf_printed === false).reverse()}
                    keyExtractor={(item) => item.id}
                    renderItem={({ item }) => (
                        <View
                            style={{borderRadius: 10, background: 'rgba(0, 0, 0, 0.11)', marginBottom: 20,
                         borderStyle: 'solid'}}
                        >
                            <TouchableOpacity testID='printItemBtn'
                            style={{flexDirection: 'row', marginHorizontal: 11, marginTop: 4, 
                            gap:13, borderStyle: 'solid', justifyContent: 'space-between'}}
                            onPress={() => {
                                handlePrinter(item)
                            }}
                            >
                                <Text numberOfLines={1} style={[styles.addfiles, {color: '#FFFFFF'}]}>
                                    {item.pdf_name.replace(/\.pdf$/, "")}
                                </Text>
                                <TouchableOpacity 
                                testID='printPdfBtn'
                                onPress={() => { handlePrinter(item) }} 
                                    style={{left: 0.75}}>
                                <i
                                    className="icon-print"
                                    style={{
                                    color: 'white',
                                    fontSize: '24px'
                                    }}
                                ></i>
                                </TouchableOpacity>
                            </TouchableOpacity>
                        </View>
                    )}
                />
            }
            {selectedTab === 'printed' && 
                <FlatList
                data={pdfs?.filter((pdf) => pdf?.is_pdf_printed).reverse()}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <View
                        style={{borderRadius: 10, background: 'rgba(0, 0, 0, 0.11)', marginBottom: 20,
                        borderStyle: 'solid'}}
                    >
                    <TouchableOpacity testID='handlePrintedBtn' style={styles.listStyle} onPress={()=>{handlePrinted(item)}}>
                        <Text numberOfLines={1} style={[styles.addfiles, {color: '#FFFFFF'}]}>
                            {item.pdf_name.replace(/\.pdf$/, "")}
                        </Text>
                    </TouchableOpacity>
                  </View>
                )}
                />
            }
            <View style={styles.bottomPrintContainer} />
        </View>
        {selectedTab === 'unprinted' &&
        <div style={styles.unPrintContainer}>
            <View style={styles.printSizeContainer} />
            <TouchableOpacity testID='addPdfBtn' onPress={()=>{setPopUpSizeModel(true)}}>
                 <LinearGradient
                    locations={[0,1]}
                    colors={[
                    'rgba(255, 255, 255, 0.04)',
                    'rgba(255, 255, 255, 0.25)',
                    'rgba(255, 255, 255, 0.00)'
                    ]} 
                    style={styles.addBtnContainer}
                >
                <Text style={styles.addfiles}>
                    Add File
                </Text>
                </LinearGradient>
            </TouchableOpacity>
        </div>
        }
    </LinearGradient>
  );
}

export default printJob;

const styles = StyleSheet.create({
    mainContainer: {
        paddingVertical: 10, 
        paddingHorizontal: 2, 
        width: '75%', 
        height:305, 
        alignSelf: 'center'
    },
    mainContainerSecond:{
        flexDirection: 'row', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        width: 375, 
        height:32, 
        paddingHorizontal: 10, 
        gap:6
    },
    activeBtn: {
     fontFamily: 'Poppins_400Regular',
     fontWeight: 400,
     fontSize: 14,
     lineHeight: 21
    },
    addBtnContainer: {
        width: 95, 
        height: 26, 
        borderRadius: 15, 
        borderStyle: 'solid', 
        borderWidth: 1,
        paddingHorizontal: 14, 
        paddingVertical: 3, 
        alignSelf: 'center',
        bottom: 'auto'
    },
    listStyle:{
        flexDirection: 'row', 
        marginHorizontal: 11, 
        marginTop: 4, 
        gap:13, 
        borderStyle: 'solid', 
        justifyContent: 'space-between', 
        borderRadius: 10, 
    },
    activeBtnContainer: {
        marginTop: '3%',
        paddingHorizontal: 5,
        borderTopLeftRadius: 5,
        borderTopRightRadius:5
    },
    addfiles:{
        fontFamily: 'Poppins_300Light',
        fontWeight: 300,
        fontSize: 16,
        lineHeight: 20,
        alignItems: 'center'
    },
    printContainer:{
        height: 20, 
        width:'100%', 
        padding: 5, 
        borderTopLeftRadius: 10, 
        borderTopRightRadius: 10, 
        backgroundColor: 'rgba(157, 176, 193, 0.3)'
    },
    bottomPrintContainer:{
        height: 20, 
        width:'100%', 
        padding: 5, 
        backgroundColor: 'rgba(157, 176, 193, 0.3)',
        borderBottomLeftRadius: 10, 
        borderBottomRightRadius: 10
    },
    unPrintContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        gap: 8
    },
    printSizeContainer:{
        height: '32%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 10,
        flexShrink: 0,
        alignSelf: 'stretch'
    },
    dropDown:{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        padding: '4px 44px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 13,
        alignSelf: 'stretch',
        borderRadius: 10,
        marginHorizontal: '12%',
        marginTop: '2%',
        color: '#fff'
    },
    sizeTextStyle:{
        overflow: 'hidden',
        color: '#FFF',
        textOverflow: 'ellipsis',
        fontFamily: 'Poppins_300Light',
        fontSize: 16,
        fontFtyle: 'normal',
        lineHeight: 'normal',
    },
    dropDownBoxStyle:{
        cursor: 'pointer',
        width: '154px',
        height: '30px',
        backgroundColor: 'transparent',
        borderBottomColor: '#fff',
        borderColor: '#fff',
        color: '#fff',
        fontSize: 16,
        overflow: 'visible'
    }
  });
  