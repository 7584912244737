import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Platform,
  Image,
  ImageBackground,
  Dimensions
} from 'react-native';
import {connect} from 'react-redux';
import {UpdateUrl} from '../../actions/updateURL';
import {Login, UserInfo,ResetPassword,SetEmailSend} from '../../actions/userAction';
import NetInfo from '@react-native-community/netinfo';
import styles from '../../style/signin';
import {LinearGradient} from 'expo-linear-gradient';
import {fontFamily} from '../../helpers/fontFamily';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import PopUpModel from '../scanpack/pop_up_model';
import Close from '../../../assets/closeicon.png';
// import {color} from 'react-native-reanimated';
// import { faL } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n-js';
import InterNetChecks from '../internateConnectionCheck';
import InerternetConnectionAlert from '../internetConnectionAlert';
import CommonPrompt from '../../commons/modal/commonPrompt';

let loginSuccess = false;
let loginFail = false;
let userInfoCheck = false;
let multiNoti = false;
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      data: {
        grant_type: 'password',
        username: '',
        password: ''
      },
      tenent: '',
      url: '',
      tenentSuffix: '.groovepacker.com',
      error_show: false,
      errorMessage: '',
      hidePassword: true,
      toggle: false,
      resetInput: '',
      modalVisible: false,
      errorMessageShow: false,
      isUsernameSaved: false,
      accountToolTip: false,
      usernameToolTip: false,
      toolTipCheck: false,
      tenentFocused: false,
      usernameFocused: false,
      passwordFocused: false,
      ModalShow: false,
      toolTipNum: 0,
      showMessage: '',
      popInternet: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onUsernameSaved = this.onUsernameSaved.bind(this);

    this.tenent = React.createRef();
    this.username = React.createRef();
    this.password = React.createRef();
  }

  toggleModal(visible) {
    this.setState({modalVisible: visible});
  }
  //////////////////For Validation
  validation = () => {
    let data = this.state.data;
    let tenent = this.state.tenent;
    if (data.username === '' || data.password === '' || tenent === '') {
      if (tenent === '') {
        this.setState({tenent_validate: true});
      }
      if (data.username === '' && data.tenent) {
        this.username.current.focus();
        this.setState({username_validate: true});
      }
      if (data.password === '') {
        this.setState({password_validate: true});
      }
    } else {
      return true;
    }
  };
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };
  keyDownPressed = event => {
    if (event.key === 'Tab' && event.code === 'Tab') {
      // if (this.state.tenentFocused || this.state.usernameFocused || this.state.passwordFocused) {
      if (this.state.tenentFocused) {
        this.tenent.current.focus();
      }
      if (this.state.usernameFocused) {
        this.username.current.focus();
      }
      if (this.state.passwordFocused) {
        this.password.current.focus();
      }
      // else{
      //   console.log("------>>>>>>")
      // }
      // }
    }
  };
  // componentDidMount(){
  //   if(this?.props?.route?.params?.status == '401'){
  //     this.setState({ errorMessageShow: true, errorMessage: "Multiple sessions detected for user." })
  //   }
  // }

  async componentDidMount() {
    this.props.route.params.currentSocket.disconnect(true);
    window.addEventListener('resize', this.updateDimensions);
    document.addEventListener('keydown', this.keyDownPressed, false);
    const tenent = await AsyncStorage.getItem('tenent');
    const sI = setInterval(() => {
      this.test();
    }, 2000);

    if (this?.props?.route?.params?.status == '401') {
      this.setState({
        errorMessageShow: true,
        errorMessage: 'Multiple sessions detected for user.'
      });
    }
    if (tenent) {
      this.setState({tenent});
      const username = await AsyncStorage.getItem('username');
      if (username) {
        this.setState({
          data: {
            ...this.state.data,
            username
          },
          tenent,
          isUsernameSaved: true
        });
        this.password.current.focus();
      } else {
        this.username.current.focus();
      }
    } else {
      this.tenent.current.focus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    if (this.state.clickTime < this.props.login.time) {
      if (
        loginSuccess === false &&
        this.props.status === 200 &&
        this.props.login.login === true
      ) {
        loginSuccess = true;
        this.props.UserInfo();

        // Reload Window to Fix issues with Socket
        window.location.reload();

        userInfoCheck = true;
      }
      if (
        loginFail === false &&
        this.props.status === '' &&
        this.props.login.login === false
      ) {
        loginFail = true;
        this.setState({
          errorMessageShow: true,
          errorMessage:
            'Unable to log in. Please check your username and password'
        });
      }
      // if(loginFail === false && this.props.status === "", this.props.login.error=='401'){
      //   loginFail = true
      //   this.setState({ errorMessageShow: true, errorMessage: "Unauthorized user please Re-try" })

      // }
    }

    if (
      userInfoCheck === true &&
      this.props &&
      this.props.userInfo &&
      this.props.userInfo !== this.state.userInfo
    ) {
      userInfoCheck = false;
      if (this.props.userInfo.is_active === false) {
        this.setState({
          errorMessageShow: true,
          errorMessage:
            'The username is no longer active. Please contact your SuperAdmin.'
        });
      } else {
        this.props.navigation.navigate('ScanPack');
      }
      this.props.UserInfo(false);
    }
    if(this.props.isEmailSend === false && this.state.modalVisible){
      this.props.SetEmailSend()
      if (this.props.sendEmailError) {
        this.setState({ modalVisible: false, ModalShow: true, showMessage: 'some thing gone wrong' })
      }
      else {
        this.setState({ modalVisible: false, ModalShow: true, showMessage: this.props.emailSendMsg })
      }
      setTimeout(() => {
        this.setState({ ModalShow: false, showMessage: '', resetInput: '' })
      }, 3000)
    }
  }

  ////////// Check the internet connection
  InternetCheck = async data => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      this.props.Login(data);
      this.setState({clickTime: new Date()});
      loginSuccess = false;
      loginFail = false;
    } else {
      this.setState({
        errorMessageShow: true,
        errorMessage: 'Please check your connection'
      });
    }
  };

  ////////////// Login function
  onSubmit = () => {
    if (this.validation() === true) {
      this.setState(
        {
          data: {
            ...this.state.data,
            username: this.state.data.username.trim(),
            password: this.state.data.password.trim()
          },
          tenent: this.state.tenent.trim()
        },
        () => {
          if (Platform.OS === 'web') {
            AsyncStorage.setItem('tenent', this.state.tenent);
            this.props.Login(this.state.data);
            this.setState({clickTime: new Date()});
            loginSuccess = false;
            loginFail = false;
          } else {
            this.InternetCheck(this.state.data);
          }
        }
      );
    }
  };
  onUsernameSaved = () => {
    if (this.state.data.username) {
      if (this.state.isUsernameSaved) {
        AsyncStorage.removeItem('username').then(() => {});
        this.setState({isUsernameSaved: false});
      } else {
        AsyncStorage.setItem('username', this.state.data.username).then(
          () => {}
        );
        this.setState({showMessage: i18n.t('signIn.saved_msg')});
        this.setState({ModalShow: true});
        this.setState({isUsernameSaved: true});
        this.password.current.focus();
      }
    } else {
      this.setState({showMessage: i18n.t('signIn.enter_name')});
      this.setState({ModalShow: true});
    }
  };

  onChange = (name, value) => {
    let data = this.state.data;
    let tenent = this.state.tenent;
    let suffix = this.state.tenentSuffix;
    if (name === 'username') {
      data['username'] = value;
      this.setState({username_validate: false, isUsernameSaved: false});
    }
    if (name === 'password') {
      data['password'] = value;
      this.setState({password_validate: false});
    }
    if (name === 'tenent') {
      this.setState({tenent: value, tenent_validate: false});
      tenent = value;
    }

    let url = `${process.env.EXPO_WEBPACK_PROTOCOL}://${tenent.trim()}.${process.env.EXPO_WEBPACK_API_HOST}`;

    this.props.UpdateUrl(url);
    this.setState({data, errorMessageShow: false});
  };

  // changeTenant = () => {
  //   let toggle = this.state.toggle;
  //   let tenentSuffix;
  //   this.setState({toggle: !this.state.toggle});
  //   if (toggle === true) {
  //     tenentSuffix = '.groovepacker.com';
  //   } else {
  //     tenentSuffix = '.dreadheadsports.com';
  //   }
  //   this.setState({tenentSuffix});
  //   let urlDomain =
  //     tenentSuffix === '.groovepacker.com'
  //       ? '.groovepackerapi.com'
  //       : tenentSuffix;
  //   let url = 'https://' + this.state.tenent + urlDomain;
  //   // let url = 'https://c0b3-2401-4900-1c08-1d0-7916-8184-4647-abce.in.ngrok.io'
  //   // "https://ed16-2409-4053-96-bba6-764c-e598-98a7-b2b3.ngrok.io"
  //   this.props.UpdateUrl(url);
  // };

  closeAlert = () => {
    this.setState({
      errorMessageShow: false
    });
  };

  toolTipFun = open => {
    if (open === '1') {
      this.setState({
        toolTipNum: open,
        usernameToolTip: !this.state.usernameToolTip
      });
    }
    if (open === '0') {
      this.setState({
        toolTipNum: open,
        accountToolTip: !this.state.accountToolTip,
        usernameToolTip: false
      });
    }
  };
  //Check Internate connectiion Here
  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({popInternet: false});
    } else {
      this.setState({popInternet: true});
    }
  };

  handleReset = () =>{
    if(this.state.tenent === ''){
      this.setState({tenent_validate: true});
      return
    }
    this.setState({ modalVisible: true })
  }

  render() {
    // if(this?.props?.route?.params?.status == '401'){
    //   multiNoti = true
    //   this.multiuserNoti()
    // }
    return (
      <>
        <ImageBackground
          style={{flex: 1}}
          source={require('../../../assets/signinpagebackground.png')}
        >
          <View
            testID='closeToolTip'
            onStartShouldSetResponder={() =>
                {
                  this.toolTipFun(this.state.toolTipNum)
                  this.setState({toolTipNum: -1})
                }
            }
          >
            <ScrollView contentContainerStyle={{flex: 1, alignItems: 'center'}}>
              <View
                style={{
                  height: '100%',
                  width: this.state.windowWidth >= 900 ? this.state.modalVisible ? '100%': '40%' : '80%'
                }}
                // onStartShouldSetResponder={() => this.state.toolTipCheck ? this.toolTipFun() : <></>}>
              >
                {this.state.errorMessageShow && (
                  <PopUpModel
                    closeAlert={this.closeAlert.bind(this)}
                    message={this.state.errorMessage}
                    messageTypeSuccess={false}
                  />
                )}
                {this.state.popInternet === false ? (
                  <InerternetConnectionAlert />
                ) : (
                  <View></View>
                )}
                {this.state.modalVisible &&
                  <CommonPrompt
                   name={'inputElement'}
                   message = {'Enter Your Username To Reset Your Password'}
                   showButtons = {true}
                   submit = {true}
                   closeAlert = {()=>{this.toggleModal(false)}}
                   placeholder = {'Enter user name'}
                   placeholderTextColor={'#acacad'}
                   loading = {this.props.isEmailSend}
                   onSubmitEditing = {()=>{this.props.ResetPassword({name: this.state.resetInput})}}
                   value = {this.state.resetInput}
                   onChangeText={(text)=>{this.setState({resetInput : text})}}
                  />}
                {this.state.ModalShow ? (
                  <View style={[styles.alertContainer]}>
                    <LinearGradient
                      testID='notification'
                      colors={['#5bbc31', '#479523', '#3f851d']}
                      style={{
                        position: 'fixed',
                        flexDirection: 'row',
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: "center",
                        top: 0,
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        padding: 4,
                        marginTop: 20,
                        shadowOffset: {width: 0, height: 0},
                        shadowRadius: 3.84,
                        shadowColor: '#000',
                        shadowOpacity: 0.9,
                        zIndex: 9,
                        width: '80%',
                        borderRadius: 5
                      }}
                    >
                      <Text
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          fontWeight: 'bold',
                          fontSize: 16,
                          color: '#fff'
                        }}
                      >
                        {this.state.showMessage}
                      </Text>
                      <TouchableOpacity
                        testID='closeButton'
                        style={{position: 'absolute', right: 11}}
                        onPress={() => {
                          this.setState({ModalShow: false});
                        }}
                      >
                        {/* <Image source={closeButton ? closeButton : ""}
                  style={{ height: 20, width: 20 }} /> */}
                        {/* <Text style={{ color: '#fff', fontSize: 14, fontWeight: 'bold' }}>OK</Text> */}
                        <Image
                          style={
                            this.state.windowWidth > 1024
                              ? {width: 22, height: 22}
                              : {width: 18, height: 18}
                          }
                          source={Close ? Close : ''}
                        />
                      </TouchableOpacity>
                    </LinearGradient>
                  </View>
                ) : (
                  <View></View>
                )}
                <View style={{alignItems: 'center', marginTop: 55, zIndex: -1}}>
                  <Image
                    style={{height: 120, width: 120}}
                    source={require('../.././../assets/groveicon.png')}
                  />
                </View>
                <View
                  dataSet={{ componentName: 'tenet' }}
                  style={{
                    marginTop: 20,
                    alignItems: 'center',
                    marginLeft: 40,
                    zIndex: 3
                  }}
                >
                  <View>
                    <Text
                      style={[
                        styles.labelText,
                        {
                          color: '#839bb0',
                          fontSize: 14,
                          fontFamily: 'Poppins_600SemiBold'
                        }
                      ]}
                    >
                      Account Name
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                      <View>
                        <TextInput
                          value={this.state.tenent}
                          name="tenent"
                          dataSet = {{componentName: 'tenent'}}
                          placeholderTextColor="#cfe3f8"
                          ref={this.tenent}
                          autoFocus={true}
                          onChangeText={text => {
                            this.onChange('tenent', text);
                          }}
                          style={[styles.inputBox]}
                          autoCapitalize="none"
                          onSubmitEditing={() => {
                            this.username.current.focus();
                          }}
                          onKeyPress={(e)=>{
                            if(e.key === 'Enter'){
                              this.username.current.focus();
                            }
                          }}
                          returnKeyType="next"
                          onFocus={() => this.setState({tenentFocused: true})}
                          onBlur={() => this.setState({tenentFocused: false})}
                        />
                      </View>
                      <TouchableOpacity
                        dataSet={{ componentName: 'accountToolTipBtn'}}
                        accessible={false}
                        focusable={false}
                        testID='accountToolTipBtn'
                        onPress={() => this.toolTipFun('0')}
                        style={[styles.imagestyle, {cursor: 'pointer', position: 'relative'}]}
                      >
                        {this.state.accountToolTip && (
                          <LinearGradient
                            testID='accountToolTip'
                            locations={[0, 1]}
                            colors={['#8a9daf', '#d7e1e9']}
                            style={{
                              padding: 10,
                              position: 'absolute',
                              zIndex: 99,
                              top: 40,
                              borderRadius: 5,
                              width: 280,
                              left: this.state.windowWidth >= 1000 ? '6%' : '-14rem'
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: fontFamily.font400,
                                fontSize: 14,
                                color: '#030303'
                              }}
                            >
                              {'Your "account name" will be the first part of ​​your GroovePacker address.Example:\n If you currently log in at:\nthebestco.groovepacker.com\nThen your account name will be:  thebestco'}
                            </Text>
                            <View
                              style={{
                                position: 'absolute',
                                right: this.state.windowWidth >= 1000 ? '92%' : '12%',
                                top: -10,
                                borderLeftWidth: 10,
                                borderLeftColor: 'transparent',
                                borderRightWidth: 10,
                                borderRightColor: 'transparent',
                                borderBottomWidth: 10,
                                borderBottomColor: '#8d9fb1'
                              }}
                            />
                          </LinearGradient>
                        )}
                        <i
                          className="icon-info-circled"
                          style={{
                            color: 'rgb(172,187,199)',
                            fontSize: '20px'
                          }}
                        ></i>
                        {/* <TouchableOpacity
                    onPress={() => this.setState({
                      accountToolTip: !this.state.accountToolTip
                    })}
                    style={styles.imagestyle}>
                    {/* <Image source={require('../../../assets/notificationicon.png')} style={{ height: 20, width: 20 }} /> */}
                        {/* </TouchableOpacity> */}
                      </TouchableOpacity>
                    </View>
                    {this.state.tenent_validate && (
                      <Text style={styles.inputError} testID='accountNameError'>
                        Field are require to submit
                      </Text>
                    )}
                  </View>
                </View>
                <View dataSet={{ componentName:'username'}} style={{marginTop: 20, alignItems: 'center', zIndex: 1}}>
                  <View>
                    <Text
                      style={[
                        styles.labelText,
                        {
                          color: '#839bb0',
                          fontSize: 14,
                          marginLeft: 35,
                          fontFamily: 'Poppins_600SemiBold'
                        }
                      ]}
                    >
                      Username
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                      <View
                        dataSet={{ componentName: 'saveUserName'}}
                        testID='saveUserName'
                        onStartShouldSetResponder={() => this.onUsernameSaved()}
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: 15,
                          marginTop: 10,
                          width: 22,
                          cursor: 'pointer'
                        }}
                      >
                        {/* <TouchableOpacity onPress={() => this.onUsernameSaved()} style={{ justifyContent: "center", alignItems: "center", marginRight: 15, marginTop: 10, width: 22 }}> */}
                        <Image
                          source={require('../../../assets/saved1.png')}
                          style={{height: 28, width: 20}}
                        />
                        <Text style={{color: '#b6c7da', fontSize: 10}}>
                          {this.state.isUsernameSaved ? 'SAVED' : 'SAVE'}
                        </Text>
                        {/* </TouchableOpacity> */}
                      </View>
                      <View>
                        <TextInput
                          value={this.state.data.username}
                          name="username"
                          dataSet = {{componentName: 'username'}}
                          placeholderTextColor="#cfe3f8"
                          ref={this.username}
                          autoFocus={true}
                          onChangeText={text => {
                            this.onChange('username', text);
                          }}
                          style={styles.inputBox}
                          autoCapitalize="none"
                          onSubmitEditing={() => {
                            this.password.current.focus();
                          }}
                          onKeyPress={(e)=>{
                            if(e.key === 'Enter'){
                              this.password.current.focus();
                            }
                          }}
                          returnKeyType="next"
                          onFocus={() => this.setState({usernameFocused: true})}
                          onBlur={() => this.setState({usernameFocused: false})}
                        />
                      </View>
                      <TouchableOpacity
                        dataSet={{ componentName: 'userNameToolTipBtn'}}
                        accessible={false}
                        focusable={false}
                        testID='userNameToolTipBtn'
                        onPress={() => this.toolTipFun('1')}
                        style={[styles.imagestyle, {cursor: 'pointer',position: 'relative'}]}
                      >
                        {/* <TouchableOpacity
                        onPress={() => this.toolTipFun("1")}
                        style={[styles.imagestyle, { cursor: 'pointer' }]}
                      > */}
                        {this.state.usernameToolTip && (
                          <LinearGradient
                            testID='userNameToolTip'
                            locations={[0, 1]}
                            colors={['#8a9daf', '#d7e1e9']}
                            style={{
                              padding: 10,
                              position: 'absolute',
                              zIndex: 99,
                              top: 40,
                              borderRadius: 5,
                              width: 300,
                              left: this.state.windowWidth >= 1000 ? '1%' : '-14rem'
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: fontFamily.font400,
                                fontSize: 14,
                                color: '#030303'
                              }}
                            >
                              The same Username and Password should function
                              on all GroovePacker apps. If you're not able to
                              log in please verify that there are no accidental
                              spaces before or after your username or password
                              and that your capslock key is not enabled as these
                              credentials are case-sensitive. Tip: If you regularly
                              log in using the same device you can use the Save
                              option to save your username so you do not have
                              to re-enter it.
                            </Text>
                            <View
                              style={{
                                position: 'absolute',
                                right:  this.state.windowWidth >= 1000 ? '90%' : '17%',
                                top: -10,
                                borderLeftWidth: 10,
                                borderLeftColor: 'transparent',
                                borderRightWidth: 10,
                                borderRightColor: 'transparent',
                                borderBottomWidth: 10,
                                borderBottomColor: '#8d9fb1'
                              }}
                            />
                          </LinearGradient>
                        )}
                        <i
                          className="icon-info-circled"
                          style={{
                            color: 'rgb(172,187,199)',
                            fontSize: '20px'
                          }}
                        ></i>
                        {/* <TouchableOpacity
                    onPress={() => this.setState({
                      usernameToolTip: !this.state.usernameToolTip
                    })}
                    style={styles.imagestyle}>
                    {/* <Image source={require('../../../assets/notificationicon.png')} style={{ height: 20, width: 20, }} /> */}
                        {/* </TouchableOpacity> */}
                      </TouchableOpacity>
                      {/* </TouchableOpacity> */}
                    </View>
                    {this.state.username_validate && (
                      <Text style={[styles.inputError, {marginLeft: 35}]}>
                        Field are require to submit
                      </Text>
                    )}
                  </View>
                </View>
                <View
                  dataSet={{ componentName:'password'}}
                  style={{
                    marginTop: 20,
                    alignItems: 'center',
                    marginLeft: 40
                  }}
                >
                  <View>
                    <Text
                      style={[
                        styles.labelText,
                        {
                          color: '#839bb0',
                          fontSize: 14,
                          fontFamily: 'Poppins_600SemiBold'
                        }
                      ]}
                    >
                      Password
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                      <View>
                        <TextInput
                          name="Password"
                          testID='passwordInput'
                          ref={this.password}
                          dataSet = {{componentName: 'password'}}
                          secureTextEntry={this.state.hidePassword}
                          placeholderTextColor="#cfe3f8"
                          autoFocus={true}
                          onChangeText={text => {
                            this.onChange('password', text);
                          }}
                          style={[styles.inputBox]}
                          autoCapitalize="none"
                          onSubmitEditing={this.onSubmit}
                          onKeyPress={(e)=>{
                            if(e.key === 'Enter'){
                              this.onSubmit();
                            }
                          }}
                          returnKeyType="next"
                          onFocus={() => this.setState({passwordFocused: true})}
                          onBlur={() => this.setState({passwordFocused: false})}
                        />
                      </View>
                      <View
                        dataSet={{ componentName:'icon-eye-off'}}
                        onStartShouldSetResponder={() =>
                          this.setState({
                            hidePassword: !this.state.hidePassword
                          })
                        }
                        style={[styles.imagestyle, {cursor: 'pointer'}]}
                      >
                        {this.state.hidePassword ? (
                          //  require('../../../assets/Eyeshow.png')
                          <i
                            className="icon-eye-off"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '25px'
                            }}
                          ></i>
                        ) : (
                          <i
                            className="icon-eye"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '25px'
                            }}
                          ></i>
                        )}
                        {/* <TouchableOpacity onPress={() => this.setState({
                    hidePassword: !this.state.hidePassword
                  })}
                    style={styles.imagestyle}>
                    {/* //require('../../../assets/eye.png')} style={{ height: 18, width: 25 }}  */}
                        {/* </TouchableOpacity> */}
                      </View>
                    </View>
                    {this.state.password_validate && (
                      <Text style={styles.inputError} testID='errorMessage'>
                        Field are require to submit
                      </Text>
                    )}
                  </View>
                </View>
                <TouchableOpacity testID='ResetPassword' onPress={() => {this.handleReset()}}>
                <View
                  dataSet={{ componentName:'ResetPassword'}}
                  style={{
                    marginTop: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {/* <TouchableOpacity onPress={() => this.setState({ modalVisible: true })} style={styles.imagestylerefresh}> */}
                  <View
                    style={styles.imagestylerefresh}
                  >
                    <i
                      className="icon-ccw"
                      style={{color: '#839bb0', fontSize: '25px'}}
                    ></i>
                    {/* <Image source={require('../../../assets/refresh.png')} style={{ height: 25, width: 25 }} /> */}
                  </View>
                  {/* </TouchableOpacity> */}
                  <Text
                    style={[
                      styles.fontSize,
                      {
                        color: '#839bb0',
                        fontFamily: 'Poppins_600SemiBold',
                        marginTop: 5,
                        marginLeft: 5
                      }
                    ]}
                  >
                    {' '}
                    Reset Your Password
                  </Text>
                </View>
                </TouchableOpacity>
                <View dataSet={{ componentName: "logInButton"}} style={{marginTop: 30, alignItems: 'center'}}>
                  <LinearGradient
                    colors={['#95abbf', '#516b83']}
                    style={{
                      borderRadius: 5,
                      width: this.state.windowWidth >= 900 ? '34%' : '40%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    {/* <TouchableOpacity onPress={() => this.onSubmit()}> */}
                    <View onStartShouldSetResponder={() => this.onSubmit()} testID='logInButton'>
                      <Text
                        style={{
                          fontFamily: 'Poppins_600SemiBold',
                          color: '#cfe3f8',
                          fontSize: 20
                        }}
                      >
                        LOG IN
                      </Text>
                    </View>
                    {/* </TouchableOpacity> */}
                  </LinearGradient>
                </View>
                <View style={{marginTop: 40, alignItems: 'center'}}>
                  <Text
                    style={{
                      color: '#839bb0',
                      fontSize: 22,
                      fontFamily: 'Poppins_600SemiBold'
                    }}
                  >
                    GroovePacker X
                  </Text>
                  <Text
                    style={{
                      color: '#839bb0',
                      fontSize: 16,
                      fontFamily: 'Poppins_600SemiBold'
                    }}
                  >
                    {process.env.EXPO_WEBPACK_APP_URL}
                  </Text>
                </View>
              </View>
            </ScrollView>
          </View>
        </ImageBackground>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    updateUrl: state.updateUrl.url,
    status: state.user.status,
    login: state.user,
    login: state.user,
    isEmailSend: state.user.isEmailSend,
    sendEmailError: state.user.sendEmailError,
    emailSendMsg: state.user.emailSendMsg,
    userInfo: state.user.userInfo
  };
};
const mapDispatchToProps = {
  UpdateUrl,
  Login,
  ResetPassword,
  SetEmailSend,
  UserInfo
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
