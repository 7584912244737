import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { BlurView } from 'expo-blur';

const ProgressBar = ({ progress }) => {
  const animatedWidth = useRef(new Animated.Value(0)).current;
  const blinkOpacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(animatedWidth, {
      toValue: progress,
      duration: 500, // Duration of the width animation
      useNativeDriver: false,
    }).start();

    if (progress < 100) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(blinkOpacity, {
            toValue: 0.5,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(blinkOpacity, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
        ])
      ).start();
    } else {
      blinkOpacity.setValue(1); // Ensure opacity is reset when progress completes
    }
  }, [progress]);

  const widthInterpolation = animatedWidth.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  });

  return (
    <LinearGradient
      colors={['#062665', '#0B6EDF', '#06B3E4']}  
      start={[0, 0.5]}
      end={[1, 0.5]}
      style={styles.container}
    >
        <Animated.View style={[styles.progressBar, { width: widthInterpolation,opacity: blinkOpacity }]}>
        <LinearGradient
        colors={['rgb(167, 173, 182)', 'rgb(114, 208, 255)', 'rgb(101, 255, 255)']}
        
        start={[1, 0.5]}
        end={[1, 0.5]}
        style={styles.progressBarContainer}
        >
          <BlurView
            style={[styles.progressBar, { width: '99%' }]}
            intensity={8}
          />
        </LinearGradient>
        </Animated.View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 6,
    backgroundColor: 'rgb(8, 173, 228)',
    borderRadius: 5,
    overflow: 'hidden',
  },
  progressBarContainer: {
    height: '100%',
    borderRadius: 10,
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#007aff',
    borderRadius: 10,
  },
});

export default ProgressBar;
