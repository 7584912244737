export function filterScannedList(items) {
  if (items?.length > 0) {
    const result = Object.values(
      items.reduce((acc, item) => {
        const { product_id, qty_remaining, scanned_qty, total_qty, ...rest } = item;
        if (acc[product_id]) {
          acc[product_id].scanned_qty += scanned_qty;
          acc[product_id].qty_remaining += qty_remaining;
        } else {
          acc[product_id] = { product_id, scanned_qty, qty_remaining, total_qty, ...rest };
        }
        return acc;
      }, {})
    );
    return result;
  }
  return []
}

export function SortList(items) {
  const list = items
  list?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
}

export function getItemSkuByOrderItemId(orderItemId, unscannedItems) {
  if (Array.isArray(unscannedItems)) {
    const item = unscannedItems.find(item => item.order_item_id === orderItemId);
    
    if (item) {
      return item.sku;
    }
  }
};