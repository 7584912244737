import AsyncStorage from '@react-native-async-storage/async-storage';
import Axios from 'axios';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { TextInput, TouchableOpacity, ScrollView } from 'react-native';
import { View, StyleSheet, Picker, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {GetBothSettings, GetGeneralSetting} from '../../actions/userAction';
import { FontAwesome5 } from '@expo/vector-icons';
import { GetAllTags, SearchTags } from '../../actions/orderActions';
import CustomCheckBox from './customCheckBox';
import styles from '../../style/orderlist';
import { LinearGradient } from 'expo-linear-gradient';
import { filterDataWithoutGroovepacker, filterDataWithGroovepacker } from './commonFunctions';
import CustomScrollBar from '../../commons/customScrollBar';

const CustomDropdown = ({ options, onSelect, title, isOpen, toggleDropdown, handleOutsideClick, testID, ordersTagsStatus, getStatus, prevlimit, prevSkip, filters, search, gridSelectionRef, selectedStates, loadTagsData, updateOrdersTagsStatus, unselectedOrderItems, setOpenEditDropdown, GetTags, filterIncludedTags, resetSelectedTags }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [dropDownOptions, setDropDownOptions] = useState(options);

  const [tags, setTags] = useState(() => {
    const data = options;
    return filterDataWithoutGroovepacker(data);
  });
  
  const [tag2, setTag2] = useState(() => {
    const data = options;
    return filterDataWithGroovepacker(data);
  });

  const handleSelectOption = (option) => {
    onSelect(option.value);
    toggleDropdown();
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        isOpen && toggleDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    GetTags();
    loadTagsData();
  }, [isOpen]);

  useEffect(() => {
    setTags(filterDataWithoutGroovepacker(options))
    setTag2(filterDataWithGroovepacker(options))
  }, [options]);

  // console.log("tags, tags2", tags, tag2)
  return (
    <View style={styles.dropdownContainer} ref={dropdownRef}>
      <TouchableOpacity testID={testID} onPress={toggleDropdown} style={styles.selectedOption}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontFamily: 'Poppins_600SemiBold', color: '#7a7a7a' }}>{title}</Text>
          <i
            className={`${!isOpen ? 'icon-down-open' : 'icon-up-open'}`}
            style={{color: '#7A7A7A', fontSize: 15}}></i>
        </View>
      </TouchableOpacity>
      {isOpen && (
        <View style={styles.optionsContainer}>
          <TouchableOpacity testID='EditTagsBtn' style={styles.addTagContainer} onPress={setOpenEditDropdown}>
            <i className="icon-plus" style={styles.icon}></i>
            <Text style={styles.addTagText}>Edit Tags</Text>
          </TouchableOpacity>
          <View style={{width: '105%', flexDirection: 'row', alignSelf: 'stretch'}}>
            <LinearGradient
              colors={['#c2c2c2', 'rgb(211, 211, 211)']} // Change these colors to your desired gradient colors
              style={{borderRadius: 5, marginLeft: 16}}
            >
              <TextInput
                testID='searchItem'
                placeholder="Type Tags to search"
                value={searchText}
                name="searchOrder"
                autoFocus={true}
                onChangeText={(text) => {
                  setSearchText(text);
                  !text ? dispatch(GetAllTags()) : dispatch(SearchTags(text))
                }}
                style={[{height: 20, paddingLeft: '5px', backgroundColor: 'none', color: '#262B30', borderRadius: 5, marginTop: '1%', marginHorizontal: 5, fontFamily: 'Poppins_300Light', paddingRight: '20px'}]}
                onKeyPress={(e)=>{
                  if(e.key === 'Enter'){
                    console.log("searchText", searchText)
                    !searchText ? dispatch(GetAllTags()) : dispatch(SearchTags(searchText))
                  }
                }}
              />
            </LinearGradient>
            <TouchableOpacity
              testID='searchItemButton'
              style={{flexDirection: 'row',  marginTop: '1%'}}
            >
              <i
                className="icon-search"
                style={{fontSize: 15, color: 'white'}}
              ></i>
            </TouchableOpacity>
            {searchText && <TouchableOpacity
              testID='closeSearch'
              style={{ marginLeft: '-20%', marginTop: '3px' }}
              onPress={() => {
                setSearchText("");
                dispatch(GetAllTags());
              }}
            >
              <i
                className="icon-cancel-circled"
                style={{ fontSize: 17, color: 'gray' }}
              ></i>
            </TouchableOpacity>}
          </View>
          <CustomScrollBar>
            <View>
              <View style={{borderBottomWidth: 1, borderColor: 'rgb(128, 128, 128)'}}>
              {tags && tags?.filter(option => option.isVisible).map((option, index) => (
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <TouchableOpacity
                      testID={index}
                      key={index}
                      style={style.option}
                      onPress={() => handleSelectOption(option)}>
                      <Text style={{color: 'white', fontFamily: 'Poppins_600SemiBold', fontSize: 16}}>{option?.name}</Text>
                    </TouchableOpacity>
                    <View style={{ ...style.colorBar, backgroundColor: option?.color, boxShadow: `0px 0px 4px 1px ${option.color}` }} >
                    </View>
                    <CustomCheckBox
                      option = {option}
                      ordersTagsStatus = {ordersTagsStatus?.tags}
                      getOrdersStatus = {getStatus}
                      prevlimit= {prevlimit}
                      prevSkip = {prevSkip}
                      filters = {filters}
                      search = {search}
                      gridSelectionRef ={gridSelectionRef}
                      selectedStates ={selectedStates}
                      loadTagsData = {loadTagsData}
                      updateOrdersTagsStatus = {updateOrdersTagsStatus}
                      unselectedOrderItems = {unselectedOrderItems}
                      filterIncludedTags = {filterIncludedTags}
                      resetSelectedTags = {resetSelectedTags}
                    />
                  </View>
              ))}
              </View>
              {tag2 && tag2?.filter(option => option.isVisible).map((option, index) => (
                <View style={{display: 'flex', flexDirection: 'row'}}>
                  <TouchableOpacity
                    testID={index}
                    key={index}
                    style={style.option}
                    onPress={() => handleSelectOption(option)}>
                    <Text style={{color: 'white', fontFamily: 'Poppins_600SemiBold'}}>{option?.name}</Text>
                  </TouchableOpacity>
                  <View style={{ ...style.colorBar, backgroundColor: option?.color, boxShadow: `0px 0px 4px 1px ${option.color}` }} >
                  </View>
                  <CustomCheckBox
                    option = {option}
                    ordersTagsStatus = {ordersTagsStatus?.tags}
                    getOrdersStatus = {getStatus}
                    prevlimit= {prevlimit}
                    prevSkip = {prevSkip}
                    filters = {filters}
                    search = {search}
                    gridSelectionRef ={gridSelectionRef}
                    selectedStates ={selectedStates}
                    loadTagsData = {loadTagsData}
                    updateOrdersTagsStatus = {updateOrdersTagsStatus}
                    unselectedOrderItems = {unselectedOrderItems}
                    filterIncludedTags = {filterIncludedTags}
                    resetSelectedTags = {resetSelectedTags}
                  />
                </View>
                
              ))}
            </View>
          </CustomScrollBar>
        </View>
      )}
    </View>
  );
};

const style = StyleSheet.create({
  option: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    color: 'white',
    border: 'none',
    maxWidth: '70%'
  },
  colorBar: {
    width: 5,
    height: 13,
    marginRight: 10,
    marginTop: 12
  }
});


export default CustomDropdown;
