import { LinearGradient } from 'expo-linear-gradient';
import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  ScrollView,
  Modal,
  FlatList,
  Button,  
} from 'react-native';
import qz from 'qz-tray';
import AsyncStorage from "@react-native-async-storage/async-storage";


import Input from '../../commons/Inputs/input';
import { RemoveKITProduct, getData } from '../../actions/productAction';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import SameBarcodePrompt from '../../commons/sameBarcodePrompt';
import ProductInfoMobile from './product_info_mobile';
import PropmtBarcode from './promptBarcode';
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBriefcase,
  faShuffle
} from '@fortawesome/free-solid-svg-icons';
import * as ImagePicker from 'expo-image-picker';
import {
  printScan,
  addImage,
  UpdateProductInfo,
  SetIsProductBarCodeAdded,
  GetProductDetail,
  GetProductInfoDetail,
  resetIsProductInfo
} from '../../actions/productAction';
import { } from '../../actions/productAction';
import { connect } from 'react-redux';
import styles from '../../style/productdetail';
import CommonStyles from './productStyles';
import CommonFunction from './commonFunction';
import RemoveImage from './removeImage';
import i18n from 'i18n-js';
import EditableInput from '../../commons/Inputs/editableInput';
import ProductInput from '../../commons/Inputs/productInfoInput';
import PrinterShow from '../directPrinting/printerShow';
import { fetchData, handlePrinterSelection } from '../../../qzservice';
import AddIconFunction from './addIconFunction';
import AddProduct from './addproduct';

let ImageId = 0;
let updateimage = false;
let qohFocus = true;
let locationPrimaryFocus = true;
let locationPrimaryQtyFocus = true;
let locationSecondaryFocus = true;
let locationSecondaryQtyFocus = true;
let locationTertiarytFocus = true;
let locationTertiaryQtyFocus = true;
let inventoryAlertLevel = true;
let inventoryTargetLevel = true;
let proName = true;
let skuName = true;
let barcodeFocus = true;
let scanQtyFocus = true;
let categoriesFocus = true;
let fnskuFocus = true;
let asinFocus = true;
let isbnFocus = true;
let eanFocus = true;
let supplierFocus = true;
let avgCostFocus = true;
let countGroupsFocus = true;
let ProductWeightFocus = true;
let inventoryWarehouses = '';
let tempInventoryWarehouses = '';
let lessCountFalse = false;
let lessCountCat = false;
let sameBarcodeCheck = false;
let sameSkuCheck = false;
let setTimeoutFlag = null;
let width = 0;
let height = 0;
let dpi =  0;
class ProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      multiInput: '',
      skusMultiInput: '',
      categoriesMultiInput: '',
      barcodesLocal: [],
      barcodenew: [],
      skusLocal: [],
      categoriesLocal: [],
      lessMore: false,
      lessMored: false,
      lessMoreMobile: false,
      notesInternal: false,
      loading: false,
      image: null,
      imageflag: false,
      ModalShow: false,
      productNameState: '',
      locationState: '',
      alertShow: false,
      qohstate: {},
      permissionState: false,
      openPrompt: false,
      openPromptBarcode: false,
      openPromptSkus: false,
      showProductAliasPage: false,
      aliasOfAnotherProduct: false,
      lastSearch: '',
      printers:[],
      printOption:false,
      modalVisible:false,
      urlSet:"",
      messageType:"",
      selectedPrinter:"",
    
    };
    this.skufocus = React.createRef();
    this.addBarcodeText = React.createRef();
    this.productName = React.createRef()
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  contentLess = () => {
    lessCountFalse = !lessCountFalse;
    this.props.updateStateSecond();
    this.setState({ lessMore: !this.state.lessMore });
  };
  contentLessd = () => {
    lessCountCat = !lessCountCat;
    this.props.updateStateSecondCat();
    this.setState({ lessMored: !this.state.lessMored });
  };
  contentLessMobile = () => {
    this.setState({ lessMoreMobile: !this.state.lessMoreMobile });
  };
  openTextField = field => {
    this.setState({ notesInternal: true });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
    if (setTimeoutFlag) {
      clearInterval(setTimeoutFlag)
    }
  }

  componentDidMount() {  
    if (this.props.productInfoObj === true) {
      lessCountFalse = false;
    }
    if (this.props.productInfoObjCat === true) {
      lessCountCat = false;
    }
    if (this.props.route.params.barCode && this.props.setFocus && this.productName.current) {
      this.productName.current.focus()
    }
    window.addEventListener('resize', this.updateDimensions);
    if (
      this.props &&
      this.props.basicinfo &&
      this.props.basicinfo.multibarcode
    ) {
      let barcodes = this.props.product.barcodes;
      this.setState({ barcodenew: this.props.product.barcodes });

      let packes = [];
      let codes = [];

      barcodes &&
        barcodes.length > 0 &&
        barcodes.map((barcode, index) => {
          codes.push(barcode.barcode);
        });

      this.setState({ barcodesLocal: codes });
    }
    if (this.props && this.props.product && this.props.product.skus) {
      let skus = [];
      this.props.product.skus &&
        this.props.product.skus.length > 0 &&
        this.props.product.skus.map((sku, index) => {
          skus.push(sku.sku);
        });
      this.setState({ skusLocal: skus });
    }
    if (this.props && this.props.product && this.props.product.cats) {
      let cats = [];
      this.props.product.cats &&
        this.props.product.cats.length > 0 &&
        this.props.product.cats.map((cat, index) => {
          cats.push(cat.category);
        });
      this.setState({ categoriesLocal: cats });
    }
  }

  componentUnmount() {
    this.setState({ barcodenew: this.props.product.barcodes });
  }

  componentDidUpdate() {
    if (sameBarcodeCheck || sameSkuCheck) {
      setTimeoutFlag = setTimeout(() => {
        sameBarcodeCheck = false
        sameSkuCheck = false
      }, 1000)
      return
    }
    if(this.state.barcodenew.length !== this.props.product.barcodes.length){
      this.setState({ barcodenew: this.props.product.barcodes });
    }
    if(this.props.isProductLoaded === false) { 
      this.setState({showProductAliasPage: false})
      this.props.resetIsProductInfo()
    }
    if (this.props?.AddImage?.product_image && updateimage) {
      let ID = this.props.product.basicinfo.id;

      this.props.GetProductDetail(ID);
      updateimage = false;
    }
    if (this.props.removefromLocal !== '') {
      this.removeByName(this.props.removefromLocal);
    }
    if (this.props.route?.params?.barCode && this.props.isProductBarCodeAdded) {
      this.updateMultiTag('barcode');
      this.props.SetIsProductBarCodeAdded(false);
      this.productName.current && this.productName.current.focus()
    }
  }

  removeByName = barcodeName => {
    let arr = this.state.barcodesLocal;
    arr &&
      arr.length &&
      arr.map((barcode, index) => {
        if (barcode === this.props.removefromLocal) {
          arr.splice(index, 1);
          return barcode
        }
      });
    this.setState({ arr });
    this.props.updateLocalBarcode();
  };

  pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    });

    let productId = this.props.product.basicinfo.id;
    let imageUri = result.uri

    this.props.addImage(productId, imageUri);
    updateimage = true;
  };

  removeImageById = id => {
    this.setState({ ModalShow: true });
    ImageId = id;
  };

  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({ permissionState: true });
      qohFocus = false;
      locationPrimaryFocus = false;
      locationPrimaryQtyFocus = false;
      locationSecondaryFocus = false;
      locationSecondaryQtyFocus = false;
      locationTertiarytFocus = false;
      locationTertiaryQtyFocus = false;
      inventoryAlertLevel = false;
      inventoryTargetLevel = false;
      proName = false;
      skuName = false;
      barcodeFocus = false;
      scanQtyFocus = false;
      categoriesFocus = false;
      fnskuFocus = false;
      asinFocus = false;
      isbnFocus = false;
      eanFocus = false;
      supplierFocus = false;
      avgCostFocus = false;
      countGroupsFocus = false;
      ProductWeightFocus = false;
    }
  };
  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({ permissionState: false });
      qohFocus = true;
      locationPrimaryFocus = true;
      locationPrimaryQtyFocus = true;
      locationSecondaryFocus = true;
      locationSecondaryQtyFocus = true;
      locationTertiarytFocus = true;
      locationTertiaryQtyFocus = true;
      inventoryAlertLevel = true;
      inventoryTargetLevel = true;
      proName = true;
      skuName = true;
      barcodeFocus = true;
      scanQtyFocus = true;
      categoriesFocus = true;
      fnskuFocus = true;
      asinFocus = true;
      isbnFocus = true;
      eanFocus = true;
      supplierFocus = true;
      avgCostFocus = true;
      countGroupsFocus = true;
      ProductWeightFocus = true;
    }
  };

  imageRemove = () => {
    const { product, UpdateProductInfo } = this.props;
    product.images = product.images.filter((image) => image.id !== ImageId);
    UpdateProductInfo(ImageId, product);
    this.setState({ ModalShow: false });
    ImageId = 0;
  };

  updateMultiTag = (type, remove) => {    
    if (type === 'barcode') {
      const { barcodesLocal, multiInput } = this.state;
      const { barCode } = this.props.route.params
      const { basicinfo, updateProductSBC } = this.props;
      let barcode = multiInput === '' && barCode ? barCode : multiInput
      let previousData = this.props.product.barcodes;
      let finalArray = [];
      let newHash = {};
      let NewArray = [];
      barcodesLocal.includes(barcode) ? sameBarcodeCheck = true : barcodesLocal.push(barcode);
      if (barcode !== ' ' || remove === true) {
        previousData.map(previousDataBarcode => {
          if (barcodesLocal.includes(previousDataBarcode.barcode)) {
            finalArray.push(previousDataBarcode);
            NewArray.push(previousDataBarcode.barcode);
          }
        });
        barcodesLocal.map(barcode => {
          if (barcode !== '' && NewArray.includes(barcode) === false) {
            newHash = {
              barcode: barcode,
              id: 'TEMP',
              packing_count: '1',
              product_id: basicinfo?.id
            };
            finalArray.push(newHash);
            return 
          }
          return barcode;
        });
        this.setState({ barcodesLocal, multiInput: '' });
        let checkRemove = remove === true ? true : false;
        if (finalArray.length > 0) {
          updateProductSBC(finalArray, 'barcode', checkRemove);
        } else {
          if (finalArray.length === 0) {
            updateProductSBC([], 'barcode');
          }
        }
      }
    }
    if (type === 'skus') {
      let sku = this.state.skusMultiInput;
      let skusLocal = this.state.skusLocal;
      let previousDataSku = this.props.product.skus;
      let finalArraySku = [];
      let newHashSku = {};
      let NewArraySku = [];
      skusLocal.includes(sku) ? sameSkuCheck = true : skusLocal.push(sku);
      if (sku !== ' ' || remove === true) {
        previousDataSku.map(previousSkus => {
          if (skusLocal.includes(previousSkus.sku)) {
            finalArraySku.push(previousSkus);
            NewArraySku.push(previousSkus.sku);
          }
        });
        skusLocal.map(sku => {
          if (sku !== '' && NewArraySku.includes(sku) === false) {
            newHashSku = {
              id: 'TEMP',
              product_id: this.props.basicinfo.id,
              sku: sku
            };
            finalArraySku.push(newHashSku);
            return
          }
          return sku
        });
        this.setState({ skusLocal, skusMultiInput: '' });
        let checkRemove = remove === true ? true : false;
        if (finalArraySku.length > 0) {
          this.props.updateProductSBC(finalArraySku, 'sku', checkRemove);
        } else {
          if (finalArraySku.length === 0) {
            this.props.updateProductSBC([], 'sku');
          }
        }
      }
    }

    if (type === 'cats') {
      let cat = this.state.categoriesMultiInput;
      let categoriesLocal = this.state.categoriesLocal;
      let previousDataCat = this.props.product.cats;
      let finalArrayCat = [];
      let newHashCat = {};
      let NewArrayCat = [];
      categoriesLocal.includes(cat) ? null : categoriesLocal.push(cat);
      if (cat !== ' ' || remove === true) {
        previousDataCat.map(previousCat => {
          if (categoriesLocal.includes(previousCat.category)) {
            finalArrayCat.push(previousCat);
            NewArrayCat.push(previousCat.category);
          }
        });
        categoriesLocal.map(cat => {
          if (cat !== '' && !NewArrayCat.includes(cat)) {
            newHashCat = {
              id: 'TEMP',
              product_id: this.props.basicinfo.id,
              category: cat
            };
            finalArrayCat.push(newHashCat);
          }
          return cat;
        });
        this.setState({ categoriesLocal, categoriesMultiInput: '' });
        let checkRemove = remove === true ? true : false;
        if (finalArrayCat.length > 0) {
          this.props.updateProductSBC(finalArrayCat, 'cats', checkRemove);
        } else {
          if (finalArrayCat.length === 0) {
            this.props.updateProductSBC([], 'cats');
          }
        }
      }
    }
  };
  changeProductKit = () => {
    this.props.productInfoHandleChange(1, 'is_kit');
    this.props.changeState('inventoryKitOptionsActive');
  };

  removeMultiTag = (type, index) => {
    if (type === 'barcode') {
      let barcodes = this.state.barcodesLocal;
      if (barcodes.length > 0) {
        barcodes.splice(index, 1);
        this.updateMultiTag('barcode', true);
      }
      this.setState({ barcodes });
    }

    if (type === 'sku') {
      let skus = this.state.skusLocal;
      if (skus.length > 0) {
        skus.splice(index, 1);
        this.updateMultiTag('skus', true);
      }
      this.setState({ skus });
    }
    if (type === 'cats') {
      let cats = this.state.categoriesLocal;
      if (cats.length > 0) {
        cats.splice(index, 1);
        this.updateMultiTag('cats', true);
      }
      this.setState({ cats });
    }
  };

  makeProductAlias = () =>{
    let productID = this.props.product?.basicinfo.id;
    !this.state.aliasOfAnotherProduct && 
      this.props.GetProductInfoDetail(productID)
  }

  submitProduct = async(barcodeNumber) => {
    let ID = this.props?.product?.basicinfo?.id;
    let settingCheck = this.props?.bothSettings?.data?.data?.general_setting?.print_product_barcode_labels
    let printData = await getData(ID,barcodeNumber,true);
    if(settingCheck){
      console.log("cehck setting here",settingCheck)
      this.setState({urlSet:printData})
      let data = await fetchData();
      if(data.printersList.length ==0){
        window.open(this.state.urlSet)
        this.props.navigation.navigate('ScanPack');
      }
      else{
      this.setState({printers:JSON.parse(JSON.stringify(data.printersList))})
      let shippingLabel =
        (await AsyncStorage.getItem('print_product_barcode_labels')) || '';
      const dimensions = this.props?.generalSettings?.data?.data?.settings.product_barcode_label_size.split('x');
      width = dimensions[0].trim();
      height = dimensions[1].trim();
      dpi = 2
      if (shippingLabel.length === 0) {
        this.setState({modalVisible:true})
        // setModal(true);
      } else {
        this.setState({modalVisible:false})
        setTimeout(() => {
          handlePrinterSelection(
            JSON.parse(shippingLabel),
            printData,
            '',
            "product_barcode",
            {width: width,height: height},
            dpi
          );
        }, [3000]);
        }
      }
    }
    else{
      this.props.printScan(ID,barcodeNumber, true);
    }
    // let productInfo="productInfo"
    // AsyncStorage.setItem('newparam',productInfo)
    // this.props.route?.params?.socketFun("productInfo")
   
  };
  updateBarcodeQty = (qty, barcode) => {
    this.setState({ barcodenew: this.props.product.barcodes });
    let ID = this.props.product.basicinfo.id;
    let productData = this.props.product;
    if (qty?.length == 0) {
      barcode['packing_count'] = null;
    }
    if (qty?.length !== 0) {
      barcode['packing_count'] = parseInt(qty);
    }
    productData?.barcodes?.map((bar, index) => {
      if (bar.id == barcode.id) {
        productData?.barcodes?.splice(index, 1, barcode);
      }
    });
    let multi = productData.basicinfo.multibarcode
    Object.keys(multi).forEach((key) => {
      if (multi[key].id == barcode.id) {
        multi[key].packcount = qty;
      }
    })
    this?.props?.UpdateProductInfo(ID, productData);
  };
  updateBarcodes = (barcode, barcodedata) => {
    let ID = this.props.product.basicinfo.id;
    let productData = this.props.product;
    productData.app = 'app'
    barcodedata['barcode'] = barcode;
    productData?.barcodes?.map((bar, index) => {
      if (bar.id == barcodedata.id) {
        barcodedata['id'] = 'TEMP';
        productData?.barcodes.splice(index, 1, barcodedata);
      }
    });
    this.props.updateProductSBC(productData.barcodes, 'barcode', false)
    this.setState({
      barcodesLocal: productData.barcodes,
      barcodenew: productData.barcodes
    })
  };
  updateSkus = (sku, skudata) => {   
    skudata['sku'] = sku;
    let productData = this.props.product;
    productData?.skus?.map((sku, index) => {
      if (sku.id == skudata.id) {
        skudata['id'] = 'TEMP';
        productData?.skus?.splice(index, 1, skudata)
      };
    });
    this.props.updateProductSBC(productData.skus,'sku', false)
  };

  kitRemove = () => {
    const { productInfoHandleChange, updateProductFun } = this.props;
    productInfoHandleChange(0, 'is_kit');
    updateProductFun('save');
  };
  update = () => {
    const { skusMultiInput, categoriesMultiInput, multiInput } = this.state;
    this.setState({ alertShow: false });
    if (!(skusMultiInput || categoriesMultiInput || multiInput)) {
      this.props.updateProductFun('save');
      return;
    }
    if (skusMultiInput) {
      this.updateMultiTag('skus');
    }
    if (categoriesMultiInput) {
      this.updateMultiTag('cats');
    }
    if (multiInput) {
      this.updateMultiTag('barcode');
    }
  };
  modelShowStateFun = () => {
    this.setState({ ModalShow: false });
  };
  skusMultiInputFun = text => {
    this.setState({ skusMultiInput: text });
  };
  multiInputFun = text => {
    this.setState({ multiInput: text });
  };
  categoriesMultiInputFun = text => {
    this.setState({ categoriesMultiInput: text });
  };

  openPromptBar = () => {
    this.setState({ openPrompt: true, openPromptBarcode: true });
  };
  onChangeTextInfo = (text, name) => {
    const canEdit = this.props.show === true && !(this.props.route?.params?.userData?.role?.edit_product_location?.editProductLocation);
    canEdit ? this.permissionCheck() : this.props.inventoryWarehousesHandleChange1(text, name);
  };

  onChangeTextDetail = (text, name) => {    
    const canEdit = this.props.show === true && !(this.props.route?.params?.userData?.role?.edit_product_location?.editProductLocation);
    canEdit ? this.permissionCheck() : this.props.productInfoHandleChange(text, name);
  }
  openPromptSku = () => {
    this.setState({ openPrompt: true, openPromptSkus: true });
  };
  closePrompt = () => {
    this.setState({
      openPrompt: false,
      openPromptSkus: false,
      openPromptBarcode: false
    });
  };
  onChangeBarcode = text => {
    this.setState({ multiInput: text });
  };
  onChangeSku = text => {
    this.setState({ skusMultiInput: text });
  };
  closeModelImg = () => {
    this.setState({ ModalShow: false });
  };
  openAlias = (aliasOfAnother) => {
    this.setState({ showProductAliasPage: true });
    this.setState({aliasOfAnotherProduct: aliasOfAnother})
  };
  getIcon = (show) => {
    if (show) {
      return <i
        className="icon-up-open"
        style={{
          color: '#cdcdcd',
          height: '26',
          width: '24'
        }}
      ></i>
    }
    else {
      return <i
        className="icon-down-open"
        style={{
          color: '#cdcdcd',
          height: '26',
          width: '24'
        }}
      ></i>
    }
  }
  render() {
    let { basicinfo, images } = this.props;
    let { openPrompt, openPromptBarcode, openPromptSkus, permissionState, windowWidth } = this.state
    inventoryWarehouses = this.props.inventoryWarehouses;
    let skus = this?.props?.product?.skus;
    let cats = this?.props?.product?.cats;
    return (
      <View>
         {this.state.modalVisible && (
        <PrinterShow
          dataPrinter={this.state.printers}
          handlePrinterSelection={async printer => {
            let printerState = await handlePrinterSelection(
              printer,
              this.state.urlSet,
              '',
              'product_barcode',
              {width: width, height: height},
              dpi
            );
            this.setState({modalVisible:false})
            this.setState({selectedPrinter:printerState.type})
          }}
          closePrompt={() => {
            this.setState({modalVisible:false})
          }}
        />
      )}
        {openPrompt ? (
          <PropmtBarcode
            openPrompt={openPrompt}
            openPromptBarcode={openPromptBarcode}
            openPromptSku={openPromptSkus}
            closePrompt={() => {
              this.closePrompt();
            }}
            updateBarcodes={(text, barcode) => {
              this.updateBarcodes(text, barcode);
            }}
            update={() => this.update()}
            onChangeBarcode={text => {
              this.onChangeBarcode(text);
            }}
            onChangeSku={text => {
              this.onChangeSku(text);
            }}
          />
        ) : (
          <></>
        )}
        {this.state.showProductAliasPage && (
          <View>
            <AddProduct 
              onchange={() => this.setState({ showProductAliasPage: !this.state.showProductAliasPage })}
              hideSaveFun={() => this.props.hideSaveFun()}
              product = {this.props.product}
              aliasProduct = {true}
              aliasOfAnother = {this.state.aliasOfAnotherProduct}
              makeProductAlias = {() => {this.makeProductAlias()}}
              upDateState = {(value)=>{this.setState({lastSearch: value})}}
              lastSearched={this.state.lastSearch}
            />
          </View>
        )}
        {
          (sameBarcodeCheck || sameSkuCheck) &&
          <SameBarcodePrompt
            name={sameBarcodeCheck ? i18n.t('product.same_barcode_found') : i18n.t('product.same_sku_found')}
            onCloseAlert={() => this.onCloseAlert()} />
        }

        <AlertBoxPermission
          permissionState={permissionState}
          onCloseAlert={() => this.onCloseAlert()}
        />
        {windowWidth >= 700 ? (
          <>
            {basicinfo && openPrompt === false && !this.state.showProductAliasPage ? (
              <>
                {images != undefined &&
                  images?.length > 0 &&
                  images?.map((img, index) => {
                    return (
                      <>
                        <RemoveImage
                          ModalShow={this.state.ModalShow}
                          closeModelImg={() => this.closeModelImg()}
                          imageRemove={() => this.imageRemove()}
                        ></RemoveImage>
                      </>
                    );
                  })}

                <View
                  testID='productInfoContainer'
                  style={{ marginLeft: '30px', marginTop: '-28px', width: 600, display: 'none' }}
                >
                  {basicinfo.is_kit == 1 ? (
                    <Text
                      style={[styles.KitHeading]}
                    >
                      KIT:{this.props.product?.skus?.[0]?.sku}
                    </Text>
                  ) : (
                    <Text
                      style={[styles.KitHeading]}
                    >
                      {this.props.product?.skus?.[0]?.sku}
                    </Text>
                  )}
                </View>
                <TouchableOpacity
                  testID='aliasOfAnother'
                  style={[CommonStyles.texAndIconContainer,{marginTop: 18}]}
                  onPress={() => {
                    this.openAlias(true)
                  }}
                >
                  <FontAwesomeIcon
                    style={CommonStyles.iconStyle}
                    icon={faArrowRightToBracket}
                  />
                  <Text
                    style={CommonStyles.textStyleTypeFour}
                  >
                    {i18n.t('product.alias_of_another')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  testID='aliasesOfThisProduct'
                  style={CommonStyles.texAndIconContainer}
                  onPress={() => {
                    this.openAlias(false)
                  }}
                >
                  <FontAwesomeIcon
                    style={CommonStyles.iconStyle}
                    icon={faArrowRightFromBracket}
                  />
                  <Text
                    style={CommonStyles.textStyleTypeFour}
                  >
                    {i18n.t('product.aliases_of_this_product')}
                  </Text>
                </TouchableOpacity>

                {basicinfo?.is_kit == 0 ? (
                  <View
                    style={CommonStyles.texAndIconContainer}
                  >
                    <TouchableOpacity
                      testID='changeToKit'
                      onPress={() => {
                        this.props.show === true
                          ? this.permissionCheck()
                          : this.changeProductKit();
                      }}
                    >
                      <FontAwesomeIcon
                        style={CommonStyles.iconStyle}
                        icon={faBriefcase}
                      />
                      <Text
                        style={CommonStyles.textStyleTypeThree}
                      >
                        {i18n.t('product.product_to_kit')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View
                    style={CommonStyles.texAndIconContainer}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        this.props.show === true
                          ? this.permissionCheck()
                          : this.kitRemove();
                      }
                      }
                    >
                      <FontAwesomeIcon
                        style={CommonStyles.iconStyle}
                        icon={faShuffle}
                      />

                      <Text
                        style={CommonStyles.textStyleTypeThree}
                      >
                        {i18n.t('product.kit_to_product')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
                <View
                  style={CommonStyles.imagesContainer}
                >
                  <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  >
                    {
                      images?.map((img, index) => {
                        return (
                          <>
                            {img?.image ? (
                              <Image
                                style={CommonStyles.imageStyle}
                                source={{ uri: img?.image }}
                                key={index}
                              />
                            ) : (
                              <View></View>
                            )}
                            <TouchableOpacity
                              testID='imageRemove'
                              onPress={() => {
                                {
                                  this.props.show === true
                                    ? this.permissionCheck()
                                    : this.removeImageById(img.id);
                                }
                              }}
                            >
                              <Text
                                style={CommonStyles.textStyleTypeTwo}
                              >
                                Remove
                              </Text>
                            </TouchableOpacity>
                          </>
                        );
                      })}
                  </ScrollView>
                  <View
                    style={CommonStyles.addImageContainer}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        this.props.show === true
                          ? this.permissionCheck()
                          : this.pickImage();
                      }
                      }
                    >
                      <LinearGradient
                        colors={[
                          // "#000",
                          // "#000",
                          '#5c778f',
                          '#5c778f',
                          // "#253c57",
                          // "#253c57",
                          '#000'
                        ]}
                        start={[-0.5, -1]}
                        style={[styles.iconPlusDesktopGradiant]}
                      >
                        <i
                          className="icon-plus"
                          style={{ color: 'white', fontSize: '65px' }}
                        ></i>
                        <Text
                          style={CommonStyles.textStyleTypeOne}
                        >
                          Add Image
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={CommonStyles.QtyContainer}
                >
                  <View style={{ margin: 10, flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <>
                      <View style={{
                        marginLeft: windowWidth >= 1440 && windowWidth < 2000  ? '2.4%' : '2.2%', width: '45%'}}>
                        <Text
                          style={[styles.productInfoText, { marginRight: '-69', marginLeft: '-3.8%' }]}
                        >
                          {i18n.t('product.QOH')}
                        </Text>
                        <ProductInput
                          style={[styles.productInfoInput, { marginLeft: "-4%", 
                          marginTop: "2%", width: 'auto' }]}
                          onChangeText={(text, name) => {
                            this.onChangeTextInfo(text, "quantity_on_hand")
                          }}
                          editable={qohFocus}
                          value={inventoryWarehouses[0]?.info?.quantity_on_hand}
                        />
                      </View>
                      <View style={{marginLeft: '5%', width: '45%'}}>
                        <Text
                          style={[styles.productInfoText, { marginRight: '-86px', marginLeft: '5px' }]}
                        >
                          {i18n.t('product.location1')}
                        </Text>
                        <ProductInput
                          style={[styles.productInfoInput, { marginTop: "2%", marginLeft: "3px", width: 'auto' }]}
                          editable={locationPrimaryFocus}
                          onChangeText={(text, name) => {
                            this.onChangeTextInfo(text, "location_primary")
                          }}
                          value={inventoryWarehouses[0]?.info?.location_primary}
                        />
                      </View>
                    </>
                  </View>
                  <TouchableOpacity testID='contentLess' onPress={() => this.contentLess()}>
                    <View
                      style={CommonStyles.alignItemsTypeOne}
                    >
                      <Text
                        style={[styles.productInfoText]}
                      >
                        {lessCountFalse ? 'less' : 'More'}
                      </Text>
                      {this.getIcon(lessCountFalse)}
                    </View>
                  </TouchableOpacity>
                  {lessCountFalse && (
                    <>
                      <View>
                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.location1')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'location_primary') }}
                            editable={locationPrimaryFocus}
                            value={inventoryWarehouses[0]?.info?.location_primary}
                            textLeft={true}
                          />
                          <CommonFunction
                            label={i18n.t('product.location_qty1')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'location_primary_qty') }}
                            editable={locationPrimaryQtyFocus}
                            value={inventoryWarehouses[0]?.info?.location_primary_qty}
                            textLeft={true}
                            right={true}
                          />
                        </View>

                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.location2')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'location_secondary') }}
                            editable={locationSecondaryFocus}
                            value={inventoryWarehouses[0]?.info?.location_secondary}
                            textLeft={true}
                          />
                          <CommonFunction
                            label={i18n.t('product.location_qty2')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'location_secondary_qty') }}
                            editable={locationSecondaryQtyFocus}
                            value={inventoryWarehouses[0]?.info?.location_secondary_qty}
                            textLeft={true}
                            right={true}
                          />
                          
                        </View>

                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.location3')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'location_tertiary') }}
                            editable={locationTertiarytFocus}
                            value={inventoryWarehouses[0]?.info?.location_tertiary}
                            textLeft={true}
                          />
                          <CommonFunction
                            label={i18n.t('product.location_qty3')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'location_tertiary_qty') }}
                            editable={locationTertiaryQtyFocus}
                            value={inventoryWarehouses[0]?.info?.location_tertiary_qty}
                            textLeft={true}
                            right={true}
                          />
                        </View>
                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.inventory_alert_level')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'product_inv_alert_level') }}
                            editable={inventoryAlertLevel}
                            value={inventoryWarehouses[0]?.info?.product_inv_alert_level}
                            textLeft={true}
                          />
                          <CommonFunction
                            label={i18n.t('product.inventory_target_level')}
                            onChangeText={(text) => { this.onChangeTextInfo(text, 'product_inv_target_level') }}
                            editable={inventoryTargetLevel}
                            value={inventoryWarehouses[0]?.info?.product_inv_target_level}
                            textLeft={true}
                            right={true}
                          />
                        </View>
                      </View>
                    </>
                  )}
                </View>
                <View
                  style={CommonStyles.QtyContainerTypeOne}
                >
                  <Text
                    style={[styles.productInfoText, { marginLeft: '15px' }]}
                  >
                    {i18n.t('product.product_name')}
                  </Text>
                  <ProductInput
                    style={[styles.productInfoInput, { width: '97%', margin: 15, }]}
                    editable={this.proName}
                    onChangeText={(text, name) => {
                      this.props.show === true
                        ? this.permissionCheck()
                        : this.props.productInfoHandleChange(text, 'name');
                    }}
                    value={basicinfo.name}
                  />
                  <Text
                    style={[styles.productInfoText, { marginLeft: '15px' }]}
                  >
                    {i18n.t('product.skus')}
                  </Text>
                  <View style={{ width: '100%' }}>
                    {skus?.length > 0 &&
                      skus?.map((sku, index) => {
                        return (
                          sku !== '' && (
                            <EditableInput
                              testID={'skuInput'}
                              onSubmitEditing={(text) => this.updateSkus(text, sku)}
                              onRemove={() => {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.removeMultiTag('sku', index);
                              }}
                              value={sku.sku}
                              index={index}
                              isAllowed={this.props.show}
                              checkPermission={this.permissionCheck}
                              id={'removeTagSku'}
                            />
                          )
                        );
                      })}
                  </View>
                  <AddIconFunction
                    onPress={() => {this.props.show ? this.permissionCheck() : this.openPromptSku()}}
                    testID={'addNewSku'}
                  />
                  <View style={{}}>
                    <View style={{ width: '60%' }}>
                      <View style={{ flexDirection: 'row', width: ' 128%' }}>
                        <Text
                          style={[styles.productInfoText, { marginTop: '1%', marginLeft: 15 }]}
                        >
                          {i18n.t('product.barcodes')}
                        </Text>

                        <Text
                          style={[CommonStyles.textStyle, {right: windowWidth > 1440 ? '0.9%':'0.1%'}]}
                        >
                          {i18n.t('product.scan_qty')}
                        </Text>
                      </View>
                      {this.state?.barcodenew?.map((barcode, index) => {
                        return (
                          barcode !== '' && (
                            <>
                              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                <EditableInput
                                  testID={'barcodeInput'}
                                  dataSet={{ componentName: 'ProductBarcodeInput' }}
                                  onSubmitEditing={(text) => this.updateBarcodes(text, barcode)}
                                  onRemove={() => {
                                    this.props.show === true
                                      ? this.permissionCheck()
                                      : this.removeMultiTag('barcode', index);
                                  }}
                                  value={barcode.barcode}
                                  index={index}
                                  id={'removeBarcode'}
                                  isAllowed={this.props.show}
                                  checkPermission={this.permissionCheck}
                                />
                                <View style={{ marginRight: '20px', flexDirection: 'row' }}>
                                  <TextInput
                                    testID='barcodeQty'
                                    style={CommonStyles.textInputStyle}
                                    value={
                                      barcode?.packing_count === null
                                        ? 0
                                        : barcode?.packing_count
                                    }
                                    onChangeText={(qty, bar) => {
                                      this.props.show === true
                                        ? this.permissionCheck()
                                        : this.updateBarcodeQty(qty, barcode);
                                    }}
                                  ></TextInput>

                                  <TouchableOpacity
                                    testID='printbyQz'
                                    onPress={() => {
                                      this.props.show === true
                                        ? this.permissionCheck()
                                        :this.submitProduct(barcode.barcode);
                                        // : this.fetchData();
                                    }}
                                  >
                                    <i
                                      className="icon-print"
                                      style={{
                                        color: 'white',
                                        fontSize: '25px',
                                        marginTop: 3
                                      }}
                                    ></i>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </>
                          )
                        );
                      })}
                    </View>
                  </View>
                  <AddIconFunction
                    onPress={() => {this.props.show ? this.permissionCheck() : this.openPromptBar()}}
                    testID={'addNewBarcode'}
                  />
                  <View>
                    <TouchableOpacity testID='showMoreIcon' onPress={() => this.contentLessd()}>
                      <View
                        style={CommonStyles.alignItemsTypeTwo}
                      >
                        <Text
                          style={[styles.productInfoText]}
                        >
                          {lessCountCat ? 'less' : 'More'}
                        </Text>
                        {this.getIcon(lessCountCat)}
                      </View>
                    </TouchableOpacity>
                    {lessCountCat && (
                      <>
                        <View style={{ margin: 10 }}>
                          <View style={{}}>
                            <Text
                              style={[styles.productInfoText]}
                            >
                              {i18n.t('product.categories')}
                            </Text>
                            <TextInput
                              style={[styles.productInfoInput, { width: '98.5%' }]}
                              onChangeText={text => {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.setState({
                                    categoriesMultiInput: text
                                  });
                              }}
                              editable={categoriesFocus}
                              value={this.state.categoriesMultiInput}
                              onSubmitEditing={() =>
                                this.updateMultiTag('cats')
                              }
                            ></TextInput>
                            <ScrollView
                              horizontal={true}
                              showsHorizontalScrollIndicator={false}
                              style={{ width: '100%' }}
                              showsVerticalScrollIndicator={false}
                            >
                              <View
                                style={CommonStyles.catContainer}
                              >
                                {cats &&
                                  cats.length > 0 &&
                                  cats.map((cat, index) => {
                                    let catLength = cat.category.length;
                                    return (
                                      cats !== '' && (
                                        <View
                                          key={index}
                                          style={CommonStyles.catSubContainer}
                                        >
                                          <TextInput
                                            style={{
                                              border: 'none',
                                              height: '31px',
                                              borderRadius: 10,
                                              paddingTop: 10,
                                              paddingBottom: 10,
                                              paddingRight: 15,
                                              paddingLeft: 5,
                                              backgroundColor: '#cdcdcd',
                                              width:
                                                catLength <= 4 ? (catLength + 2) * 12 + 'px' : catLength <= 7 ? (catLength + 2) * 11 + 'px' : (catLength + 2) * 10 + 'px',
                                              color: '#1d3045',
                                              fontFamily: 'Poppins_500Medium',
                                              fontSize: 15
                                            }}
                                            value={cat.category}
                                            editable={false}
                                          />
                                          <TouchableOpacity
                                            testID='removeTagCat'
                                            key={index}
                                            onPress={() => {
                                              this.props.show === true
                                                ? this.permissionCheck()
                                                : this.removeMultiTag(
                                                  'cats',
                                                  index
                                                );
                                            }}
                                            style={CommonStyles.crossButton}
                                          >
                                            <i
                                              className="icon-cancel"
                                              style={{
                                                fontSize: '22px',
                                                color: '#2b3d4b',
                                                marginTop: -2
                                              }}
                                            ></i>
                                          </TouchableOpacity>
                                        </View>
                                      )
                                    );
                                  })}
                              </View>
                            </ScrollView>
                          </View>
                        </View>

                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.fnsku')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'fnsku') }}
                            editable={fnskuFocus}
                            value={basicinfo.fnsku == null ? '' : basicinfo.fnsku}
                          />
                          <CommonFunction
                            label={i18n.t('product.asin')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'asin') }}
                            editable={asinFocus}
                            value={basicinfo.asin == null ? '' : basicinfo.asin}
                            right={true}
                          />
                        </View>

                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.isbn')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'isbn') }}
                            editable={isbnFocus}
                            value={basicinfo.isbn == null ? '' : basicinfo.isbn}
                          />
                          <CommonFunction
                            label={i18n.t('product.ean')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'ean') }}
                            editable={eanFocus}
                            value={basicinfo.ean == null ? '' : basicinfo.ean}
                            right={true}
                          />
                        </View>

                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.supplier_sku')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'supplier_sku') }}
                            editable={supplierFocus}
                            value={basicinfo.supplier_sku == null ? '' : basicinfo.supplier_sku}
                          />
                          <CommonFunction
                            label={i18n.t('product.avg_cost')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'avg_cost') }}
                            editable={avgCostFocus}
                            value={basicinfo.avg_cost == null ? '' : basicinfo.avg_cost}
                            right={true}
                          />
                        </View>

                        <View style={CommonStyles.costContainer}>
                          <CommonFunction
                            label={i18n.t('product.count_group')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'count_group') }}
                            editable={countGroupsFocus}
                            value={basicinfo.count_group == null ? '' : basicinfo.count_group}
                          />
                          <CommonFunction
                            label={i18n.t('product.product_weight')}
                            onChangeText={(text) => { this.onChangeTextDetail(text, 'weight') }}
                            editable={ProductWeightFocus}
                            value={basicinfo.weight == null ? '' : basicinfo.weight}
                            right={true}
                          />
                        </View>
                      </>
                    )}
                  </View>
                </View>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          // Mobile View Start from here
          <ProductInfoMobile
            {...this.props}
            kitRemove={() => this.kitRemove()}
            update={() => this.update()}
            updateCats={(cat, catdata) => this.updateCats(cat, catdata)}
            updateSkus={(sku, skudata) => this.updateSkus(sku, skudata)}
            updateBarcodes={(text, barcode) => {
              this.updateBarcodes(text, barcode);
            }}
            productName={this.productName}
            updateBarcodeQty={(qty, barcode) =>
              this.updateBarcodeQty(qty, barcode)
            }
            submitProduct={(barcodeNumber) => {
              this.submitProduct(barcodeNumber);
            }}
            removeMultiTag={(type, index) => {
              this.removeMultiTag(type, index);
            }}
            changeProductKit={() => {
              this.changeProductKit();
            }}
            updateMultiTag={(type, remove) => {
              this.updateMultiTag(type, remove);
            }}
            imageRemove={() => {
              this.imageRemove();
            }}
            keyupPressed={(val, text) => this.keyupPressed(val, text)}
            permissionCheck={() => this.permissionCheck()}
            removeImageById={id => {
              this.removeImageById(id);
            }}
            pickImage={() => {
              this.pickImage();
            }}
            openTextField={() => {
              this.openTextField();
            }}
            contentLessMobile={() => this.contentLessMobile()}
            contentLessd={() => {
              this.contentLessd();
            }}
            contentLess={() => {
              this.contentLess();
            }}
            windowWidth={windowWidth}
            ModalShow={this.state.ModalShow}
            modelShowStateFun={() => this.modelShowStateFun()}
            lessMoreMobile={this.state.lessMoreMobile}
            skusMultiInput={this.state.skusMultiInput}
            skusMultiInputFun={text => {
              this.skusMultiInputFun(text);
            }}
            onChangeTextInfo={(text, name) => this.onChangeTextInfo(text, name)}
            onChangeTextDetail={(text, name) => this.onChangeTextDetail(text, name)}
            barcodenew={this.state.barcodenew}
            notesInternal={this.state.notesInternal}
            multiInput={this.state.multiInput}
            multiInputFun={text => this.multiInputFun(text)}
            categoriesMultiInput={this.state.categoriesMultiInput}
            categoriesMultiInputFun={text => this.categoriesMultiInputFun(text)}
            qohFocus={qohFocus}
            locationPrimaryFocus={locationPrimaryFocus}
            locationPrimaryQtyFocus={locationPrimaryQtyFocus}
            locationSecondaryFocus={locationSecondaryFocus}
            locationSecondaryQtyFocus={locationSecondaryQtyFocus}
            locationTertiarytFocus={locationTertiarytFocus}
            locationTertiaryQtyFocus={locationTertiaryQtyFocus}
            inventoryAlertLevel={inventoryAlertLevel}
            inventoryTargetLevel={inventoryTargetLevel}
            proName={proName}
            skuName={skuName}
            barcodeFocus={barcodeFocus}
            scanQtyFocus={scanQtyFocus}
            categoriesFocus={categoriesFocus}
            fnskuFocus={fnskuFocus}
            asinFocus={asinFocus}
            isbnFocus={isbnFocus}
            eanFocus={eanFocus}
            supplierFocus={supplierFocus}
            avgCostFocus={avgCostFocus}
            countGroupsFocus={countGroupsFocus}
            ProductWeightFocus={ProductWeightFocus}
            lessCountFalse={lessCountFalse}
            lessCountCat={lessCountCat}
            openPrompt={this.state.openPrompt}
            showProductAliasPage={this.state.showProductAliasPage}
            openPromptBar={() => {
              this.openPromptBar();
            }}
            getIcon = {(show)=>{this.getIcon(show)}}
            openPromptSku={() => {
              this.openPromptSku();
            }}
            openAlias={(aliasOfAnother) => {this.openAlias(aliasOfAnother);}}
          />
        )}
      </View>
    );
  }
}
const mapStateToProps = state => {
  return {
    AddImage: state.product.AddImage,
    isProductBarCodeAdded: state.product.isProductBarCodeAdded,
    isProductLoaded: state.product.isProductLoaded
  };
};

const mapDispatchToProps = {
  printScan,
  addImage,
  UpdateProductInfo,
  GetProductDetail,
  SetIsProductBarCodeAdded,
  GetProductInfoDetail,
  resetIsProductInfo,
  RemoveKITProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);
