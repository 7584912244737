import {
  SUBMIT_LOG,
  SCANPACK_BUG_REPORT,
  SUBMIT_LOG_FAIL,
  SCANPACK_BUG_REPORT_FAIL,
  SET_STATUS_MULTI_BOX,
} from '../constants';

const initialState = {
  statusMulti:false,
};

const saveLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_LOG:
      return {
        ...state,
        logs: action.payload,
        updateLog: true,
        time: action.time
      };
    case SUBMIT_LOG_FAIL:
      return {
        ...state,
        logs: action.payload,
        updateLog: true,
        time: action.time
      };
    case SET_STATUS_MULTI_BOX:
      return {
        ...state,
        statusMulti: true
      };
    case SCANPACK_BUG_REPORT:
      return {...state, updateReportLog: action.payload};
    case SCANPACK_BUG_REPORT_FAIL:
      return {...state, updateReportLog: action.payload};
    default:
      return state;
  }
};

export default saveLogReducer;
