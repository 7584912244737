import React, { useState, useEffect, useRef } from 'react';
import { ScrollView, View, PanResponder, Animated, StyleSheet, Dimensions } from 'react-native';

const remToPx = (rem) => rem * 16; // Assuming 1 rem = 16 pixels, adjust as necessary

const CustomScrollBar = ({ children }) => {
  const [layoutHeight, setLayoutHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const scrollY = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef(null);
  let prevY = useRef(0).current;

  useEffect(() => {
    scrollY.addListener(({ value }) => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({ y: value, animated: false });
      }
    });
    return () => {
      scrollY.removeAllListeners();
    };
  }, []);

  const scrollIndicatorHeight = remToPx(22) * (layoutHeight / contentHeight); // Convert rem to px

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: (_, gestureState) => {
      const yChange = gestureState.moveY - prevY;
      const increment = 10; // Fixed increment value
      let newScrollPosition = scrollY.__getValue() + (yChange > 0 ? increment : -increment);

      // Ensure new scroll position is within bounds
      newScrollPosition = Math.max(0, Math.min(newScrollPosition, contentHeight - layoutHeight));

      scrollY.setValue(newScrollPosition);
      prevY = gestureState.moveY; // Update previous Y position
    },
    onPanResponderGrant: (_, gestureState) => {
      prevY = gestureState.moveY; // Initialize previous Y position on start of drag
    },
  });

  const handleScroll = (event) => {
    const yOffset = event.nativeEvent.contentOffset.y;
    scrollY.setValue(yOffset);
  };

  const showScrollBar = contentHeight > layoutHeight;

  return (
    <View style={styles.container}>
      <ScrollView
        ref={scrollViewRef}
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={(contentWidth, height) => setContentHeight(height)}
        onLayout={(event) => setLayoutHeight(event.nativeEvent.layout.height)}
        onScroll={handleScroll}
        scrollEventThrottle={16}
      >
        {children}
      </ScrollView>
      {showScrollBar && (
        <View style={styles.scrollBarContainer}>
          <Animated.View
            {...panResponder.panHandlers}
            style={[
              styles.scrollIndicator,
              {
                height: scrollIndicatorHeight,
                transform: [
                  {
                    translateY: scrollY.interpolate({
                      inputRange: [0, contentHeight - layoutHeight],
                      outputRange: [0, layoutHeight - scrollIndicatorHeight],
                      extrapolate: 'clamp',
                    }),
                  },
                ],
              },
            ]}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },
  scrollView: {
    flex: 1,
  },
  scrollBarContainer: {
    width: remToPx(1.5), // Assuming scrollbar width, adjust as needed
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  scrollIndicator: {
    width: 9, // Assuming width of the scrollbar indicator
    borderRadius: 4,
    backgroundColor: '#1a1d20',
    position: 'absolute',
  },
});

export default CustomScrollBar;
