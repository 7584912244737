import AsyncStorage from '@react-native-async-storage/async-storage';

export const navigateFromOrdersIfNeeded = (props, state) => {
  if (
    props?.route?.params?.data?.from === 'orderDetailPage' &&
    state.productSearchData.length === 0 &&
    props.isOrderCreated
  ) {
    props.navigation.navigate('ScanPack');
  }
};

export const getStatus = (selectedStates) => {
  return selectedStates ? selectedStates : 'all';
}

export const loadTagsData = async ({
  selectedStates,
  gridSelectionRef,
  ordersTags,
  prevlimit,
  prevSkip,
  searchData,
  PresentFilterValues,
  isFirstTime,
  tags_selected,
  filterIncludedTags
}) => {
  let select_all = false;
  let gridSelectionIdsArray = Object.values(gridSelectionRef?.selected || {}).map(item => ({ id: item.currentItem.id }));
  gridSelectionIdsArray = gridSelectionIdsArray.length === 0
    ? Object.values(gridSelectionRef?.data || {}).map(item => ({ id: item?.currentItem?.id }))
    : gridSelectionIdsArray;
  
  const orders_id = encodeURIComponent(JSON.stringify(gridSelectionIdsArray));

  if (gridSelectionRef?.selected === true) select_all = true;

  let status = getStatus(selectedStates);
  if (gridSelectionRef?.selected === true && selectedStates.length === 0) {
    status = 'all';
  }
  const tags = ordersTags?.data;
  const tagsArray = Object.keys(tags).filter(key => !isNaN(key)).map(key => tags[key]);

  const userUrl = await AsyncStorage.getItem('url');
  const access_token = await AsyncStorage.getItem('access_token');
  const filters = encodeURIComponent(JSON.stringify(PresentFilterValues));
  const tagsArrayEncoded = encodeURIComponent(JSON.stringify(tagsArray));
  // debugger
  const url = `${userUrl}/orders/check_orders_tags.json?filter=${status}&order=DESC&limit=${prevlimit}&sort=&offset=${prevSkip}&app=&count=&filters=${filters}&search=${encodeURIComponent(searchData.search || "")}&tags=${""}&select_all=${select_all}&orderArray=${orders_id}&isFirstTime=${isFirstTime}&tags_name=${tags_selected}&filterIncludedTags=${filterIncludedTags}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      console.log(`HTTP error! Status: ${response.status}`);
    }
  
    const ordersTagsStatus = await response.json();
    console.log("Response data: ", ordersTagsStatus);
    return ordersTagsStatus;
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }  
};

export const filterDataWithoutGroovepacker = (data) => {
  if(data){
    return Object.values(data).filter(item => !item.groovepacker_tag_origin);
  }
};

export const filterDataWithGroovepacker = (data) => {
  if(data){
    return Object.values(data).filter(item => item.groovepacker_tag_origin);
  }
};
