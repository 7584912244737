import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import {
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  Dimensions
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faMailBulk, faBoxOpen, faCopy} from '@fortawesome/free-solid-svg-icons';
import * as Clipboard from 'expo-clipboard';
import PackingCam from './packing_cam';
import CommonStyles from '../../style/commonStyles';
import {RemoveOrderImage} from '../../actions/orderActions';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import AsyncStorage from '@react-native-async-storage/async-storage';
import OrderInfoHeader from './order-info-header';
import i18n from 'i18n-js';
import Alert from './alert';
import EmailSentPopup from './alerEmail';
const dimensions = Dimensions.get('window');
let imageHeight = Math.round((dimensions.width * 6) / 16);
function PackingCamDetail(props) {
  const {
    navigation,
    order,
    isRemovingImage,
    updateBasicInfo,
    back,
    bothSettings
  } = props;
  const [isModalVisible, setModalVisibilty] = useState(false);
  const dispatch = useDispatch();
  const [imageDimensions, setImageDimensions] = useState(imageHeight);
  const [permissionState, setpermissionState] = useState(false);
  const [showEmailPrompt, setShowEmailPrompt] = useState(false);
  const [emailSentPopup, setEmailSentPopup] = useState(false)
  const [enteredEmail, setEnteredEmail] = useState('');
  const [tenentName, setTenent] = useState('');
  const removeImageById = (orderid, imageid) => {
    dispatch(RemoveOrderImage(orderid, imageid));
  };
  let Email = props.order.basicinfo.email;
  const increment_id = encodeURIComponent(props.order.basicinfo.increment_id);
  const updateSize = useCallback(function () {
    setImageDimensions(Math.round((Dimensions.get('window').width * 6) / 16));
  });
  const emailSend =
    props?.bothSettings?.data?.data?.scanpack_setting?.email_customer_option;
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const permissionCheck = () => {
    if (props.show === true) {
      setpermissionState(true);
    }
  };
  useEffect(() => {
    tenantCheck();
  }, []);

  const tenantCheck = async () => {
    const tenentName = await AsyncStorage.getItem('tenent');
    setTenent(tenentName);
  };
  const copyToClipboard = async () => {
    if(Email !== '')
    {
      await Clipboard.setStringAsync(`${url}`);
      return
    }
    setShowEmailPrompt(true);
  };

  const checkEmail = () => {
    let id = props.order.basicinfo.id;
    props.emailSend(id);
  };

  const onCloseAlert = () => {
    if (props.show === true) {
      setpermissionState(false);
    }
  };

  const handlePress = () => {
    if (emailSend === true && props.order.basicinfo.email) {
      setEmailSentPopup(true)
      checkEmail();
    } 
    else if(!props.order.basicinfo.email) {
      setShowEmailPrompt(true);
    }
  };
  
  const url = `https://${tenentName}.groovepacker.com/#/package-details/${Email}/${increment_id}`;
  const CustomePage = () => {
    if(Email !== '')
    {
      window.open(url);
      return
    }
    setShowEmailPrompt(true);
  };
  return (
    <>
      {showEmailPrompt && (
        <Alert
          closeAlert={() => {
            setShowEmailPrompt(false)
          }}
          submitAlert={()=> {
            props.order.basicinfo.email = enteredEmail
            updateBasicInfo(true);
            Email = enteredEmail
            setShowEmailPrompt(false)
          }}
          notes_fromPacker={enteredEmail}
          noteUpdate={text =>
            setEnteredEmail(text)
          }
          showIcon={true}
          showButtons={false}
          msg={i18n.t('packingCamDetail.enterEmail')}
          placeholder={i18n.t('packingCamDetail.placeholder')}
          placeholderTextColor={'#898989'}
        />
      )}
      {
        emailSentPopup && (
          <EmailSentPopup
            closeAlertEmail ={()=>{
              setEmailSentPopup(false)
            }}
            msg = {i18n.t('packingCamDetail.emailSentPopup')}
            >
          </EmailSentPopup>
        )
      }
      <AlertBoxPermission
        permissionState={permissionState}
        onCloseAlert={() => onCloseAlert()}
      />
      <ScrollView
        contentContainerStyle={{flexGrow: 1}}
        showsVerticalScrollIndicator={false}>
        <View
          testID='packingCamContainer'
          style={CommonStyles.packingCamContainer}
        />
        <View
          style={{
            flex: 1,
            paddingLeft: 20,
            paddingRight: 20
          }}>
          <OrderInfoHeader
            updateOrderStatus={props.updateOrderStatus}
            onSave={updateBasicInfo}
            show={props.show}
            onCancel={back}
            status={order.basicinfo.status}
            id={order.basicinfo}
          />
          {/* <Text
                    style={{
                      fontSize: 22,
                      fontWeight: "bold",
                      paddingHorizontal: 15,
                      fontFamily: "Poppins_700Bold",
                      "background": "-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)", "WebkitTextFillColor": "transparent", "webkitBackgroundClip": "text",
                    }}
                >
                    Order # {order.basicinfo.increment_id}
                </Text> */}
          <View
            style={CommonStyles.packingCamTextContainer}
          >
            <Text
              style={CommonStyles.packingCamText}
            >
              Packing Cam Images
            </Text>
            <TouchableOpacity
              style={{alignSelf: 'flex-end', marginEnd: '2%'}}
              key="add-image-button"
              testID='Button'
              onPress={() => {
                {
                  props.show === true
                    ? permissionCheck()
                    : setModalVisibilty(true);
                }
              }}
              // onPress={() => setModalVisibilty(true)}
            >
              <LinearGradient
                colors={['#5c778f', '#5c778f', '#000']}
                start={[-0.5, -1]}
                style={CommonStyles.packingCamContainerOne}
                >
                <i
                  className="icon-plus"
                  style={{color: 'white', fontSize: '65px'}}></i>
              </LinearGradient>
              <Text
                style={CommonStyles.packingCamTextOne}
                >
                Add Image
              </Text>
            </TouchableOpacity>
            <ScrollView
              contentContainerStyle={CommonStyles.scrollViewContainer}
              >
              {order.packing_cams != undefined &&
                order.packing_cams?.length > 0 &&
                order.packing_cams?.map(img => {
                  return (
                    <View
                      testID='packingCamImage'
                      key={img.id}
                      style={CommonStyles.imageContainer}
                      >
                      <Image
                        style={{
                          height: imageDimensions,
                          width: imageDimensions,
                          maxWidth: '250px',
                          maxHeight: '250px',
                          borderWidth: 1,
                          borderRadius: 8,
                          borderBottomWidth: 0
                        }}
                        source={{uri: img?.url}}
                      />
                      <TouchableOpacity
                        testID='RemoveButton'
                        onPress={() => {
                          {
                            props.show === true
                              ? permissionCheck()
                              : removeImageById(order.basicinfo.id, img.id);
                          }
                        }}
                        // onPress={() => {
                        //   removeImageById(order.basicinfo.id, img.id);
                        // }}
                      >
                        <Text
                          style={CommonStyles.packingCamTextOne}
                          >
                          Remove
                        </Text>
                      </TouchableOpacity>
                    </View>
                  );
                })}

              <View style={{width: '100%'}}>
                <View
                  style={CommonStyles.packingCamTextContainerOne}
                  >
                  {/* <i className = "icon-info-circled" style={{color: "white",fontSize: "80px"}}></i> */}
                  {/* <i className = "faArrowRightToBracket" style={{fontSize: "80px",color: "white"}}></i> */}
                  <FontAwesomeIcon
                    style={CommonStyles.packingCamIcon}
                    icon={faMailBulk}
                  />
                  {/* <Image
                    style={{ width: 20, height: 20, resizeMode: "contain" }}
                    source={require("../../../assets/arrow-right.png")}
                  /> */}
                  <TouchableOpacity
                    testID='EmailButton'
                    onPress={handlePress}>
                    <Text style={CommonStyles.packingCamTextTwo}>
                      Email Customer
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={CommonStyles.packingCamTextContainerOne}
                  >
                  <FontAwesomeIcon
                    style={CommonStyles.packingCamIcon}
                    icon={faBoxOpen}
                  />
                  <TouchableOpacity testID='customPageButton'  onPress={() => CustomePage()}>
                    <Text style={CommonStyles.packingCamTextTwo}>
                      Open Packing Cam Customer Page
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={[CommonStyles.packingCamTextContainerOne,{marginBottom: 25}]}
                  >
                  <FontAwesomeIcon
                    style={CommonStyles.packingCamIcon}
                    icon={faCopy}
                  />
                  <TouchableOpacity onPress={copyToClipboard} testID='copyPageUrl'>
                    <Text style={CommonStyles.packingCamTextTwo}>
                      Copy Customer Page URL
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </ScrollView>
      {isModalVisible && (
        <View
          testID='packingCam'
          style={CommonStyles.packingCamModalVisible}
          >
          <PackingCam
            navigation={navigation}
            shouldNavigate={false}
            hideModal={() => {
              setModalVisibilty(false);
            }}
            route={{params: {order: {id: order.basicinfo.id}}}}
          />
        </View>
      )}
      {isRemovingImage && (
        <View
          testID='removeImage'
          style={CommonStyles.packingCamRemoveImage}
          >
          <ActivityIndicator size="large" color="#fff" />
        </View>
      )}
    </>
  );
}

export default PackingCamDetail;
