import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import DateTimePicker from 'react-datetime-picker';
import '../components/orders/custom_date_picker.css';

const DatePicker = ({startDate, endDate, setStartDate, setEndDate}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [startDate, endDate]);
  // console.log("startDate", startDate, "endDate", endDate)
  return (
    <View style={styles.datePickerContainer}>
      <View style={styles.datePickerWrapper}>
        {!startDate && <Text style={styles.placeholderText}>Start Date</Text>}
        <div
          key={`start-${key}`}
          className={`custom-date-time-picker ${startDate ? 'has-input' : ''}`}>
          <DateTimePicker
            testID="orderStartDateRange"
            onChange={setStartDate}
            value={startDate}
            format="MM-dd-yyyy"
          />
        </div>
      </View>
      <View style={styles.datePickerWrapper}>
        {!endDate && <Text style={styles.placeholderText}>End Date</Text>}
        <div
          key={`end-${key}`}
          className={`custom-date-time-picker ${endDate ? 'has-input' : ''}`}>
          <DateTimePicker
            testID="orderEndDateRange"
            onChange={setEndDate}
            value={endDate}
            format="MM-dd-yyyy"
            className="custom-date-time-picker"
          />
        </div>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    marginRight: '12px',
    position: 'relative'
  },
  datePickerWrapper: {
    position: 'relative',
    flex: 1,
    border: 'none',
    marginBottom: '2px'
  },
  placeholderText: {
    position: 'absolute',
    top: 7,
    left: 10,
    color: 'white',
    fontSize: 12,
    zIndex: 1
  }
});

export default DatePicker;
